/* eslint-disable prettier/prettier */
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import Button from "../../../../components/ui/Button/Button"
import { Icon } from "../../../../components/ui/Icon/Icon"
import Sticky from "../../../../components/ui/Sticky/Sticky"
import Tooltip from "../../../../components/ui/Tooltip/Tooltip"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import { numberWithCommas } from "../../../../components/utils/numberUtils"
import { useStore } from "../../../../store"
import { transformEstimate } from "../../../../store/opportunities/opportunities.transformer"

import styles from "./AmountSection.module.scss"

export interface AmountSectionProps {}

const AmountSection: React.FC<AmountSectionProps> = observer(() => {
  const history = useHistory()
  const { opportunitiesStore, accountStore } = useStore()
  const {
    selectedKeywords,
    switchKeyword,
    calculatorStore,
  } = opportunitiesStore
  const { accountId } = accountStore

  const { ctr } = calculatorStore

  const handleClick = () => {
    history.push(`/account/${accountId}/create-campaign`)
  }

  const totalAmount = useMemo(
    () =>
      selectedKeywords.reduce(
        (acc, item) => acc + transformEstimate(item, ctr),
        0
      ),
    [selectedKeywords, ctr]
  )

  return (
    <div className={styles.root}>
      <Sticky stickyStyle={{ bottom: "20px" }} bottomOffset={20}>
        <div style={{ minHeight: "calc(100vh - 200px)", borderRadius: "10px" }}>
          <span className={styles.box}>
            <Sticky topOffset={-180} stickyStyle={{ top: "180px", zIndex: 2}}>
              <div className={styles.header}>
                <Typography type={TextType.h1} color={TextColorType.dark}>
                  Selected Keyword
                </Typography>
              </div>
            </Sticky>
            <div className={styles.keywordList}>
              <Sticky topOffset={-180} stickyStyle={{ top: "255px", zIndex: 2 }}>
                <div className={styles.labelsWrapper}>
                  <Typography
                    type={TextType.h6}
                    color={TextColorType.dark}
                    upperCase
                  >
                    Keywords
                  </Typography>
                  <Typography
                    type={TextType.h6}
                    color={TextColorType.lightGray}
                    upperCase
                  >
                    Est Monthly Ad Spend
                  </Typography>
                </div>
              </Sticky>
              {!selectedKeywords.length ? (
                <Typography type={TextType.h3} color={TextColorType.gray}>
                  No keywords yet added
                </Typography>
              ) : (
                selectedKeywords.map((item) => (
                  <Pair
                    id={item.id}
                    accountId={accountId}
                    label={item.name}
                    value={transformEstimate(item, ctr)}
                    onClose={switchKeyword}
                    renderValue={(value) => `$ ${value}`}
                    key={item.id}
                  />
                ))
              )}
            </div>

            <div className={styles.footer}>
              <Pair
                label="Total Ad Spend"
                value={totalAmount}
                renderValue={(value) => `$ ${value}`}
                strong
              />
              <div className={styles.confirm}>
                <Button
                  onClick={handleClick}
                  variant={ButtonType.contained}
                  color={ButtonColor.primary}
                  fluid
                >
                  Confirm and Create Campaigns
                </Button>

                <Typography type={TextType.h4}>
                  By selecting confirm, these attributes will be added you
                  account within 24 hours.
                </Typography>
              </div>
            </div>
          </span>
        </div>
      </Sticky>
    </div>
  )
})

export interface PairProps {
  id?: number
  accountId?: number
  label: string
  value: number
  strong?: boolean
  onClose?: (accountId: number, keywordId: number) => void
  renderValue?: (value: number | string) => string
}

export const Pair: React.FC<PairProps> = ({
  id = 0,
  label,
  value,
  strong = false,
  onClose,
  renderValue,
  accountId,
}) => (
  <div className={clsx(styles.pair, { [styles.closable]: onClose })}>
    {onClose ? (
      <Icon
        name="cross"
        className={styles.closeButton}
        onClick={() => accountId && onClose(accountId, id)}
      />
    ) : null}
    <span className={styles.column}>
      <Tooltip text={label} type={TextType.h3}>
        <Typography
          type={TextType.h3}
          color={strong ? TextColorType.dark : TextColorType.gray}
          bold={strong}
        >
          {label}
        </Typography>
      </Tooltip>
    </span>
    <span className={styles.column}>
      <Typography type={TextType.h3} bold={strong}>
        {renderValue
          ? renderValue(numberWithCommas(value))
          : numberWithCommas(value)}
      </Typography>
    </span>
  </div>
)

export default AmountSection
