export const numberWithCommas = (x: number = 0, fixed: number = 0) =>
  x.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",")

export const randomNumber = (min: number, range: number) =>
  Math.floor(Math.random() * range) + min

export const randomNumbersArray = (length: number, max: number) =>
  Array.from(Array(length).keys()).map(() => randomNumber(0, max))

export default { numberWithCommas, randomNumber, randomNumbersArray }

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const getMonth = (date: Date) => monthNames[date.getMonth()]
