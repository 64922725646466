/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from "clsx"
import React, { CSSProperties, ReactNode } from "react"
import { TextColorType, TextType } from "../../utils/enums"
import styles from "./Typography.module.scss"

export interface ITypographyProps {
  children?: ReactNode
  type: TextType
  bold?: boolean
  color?: TextColorType
  className?: string
  title?: string
  upperCase?: boolean
  style?: CSSProperties
  onClick?: () => void
}

export const Typography: React.FC<ITypographyProps> = ({
  children,
  type = TextType.h3,
  color = TextColorType.dark,
  bold = false,
  upperCase = false,
  className,
  title,
  style,
  onClick,
}: ITypographyProps) => (
  <div
    style={style}
    className={clsx(styles.root, className, styles[type], styles[color], {
      [styles.bold]: bold,
      [styles.upperCase]: upperCase,
    })}
    onClick={onClick}
    role="button"
    title={title}
  >
    {children}
  </div>
)

export default Typography
