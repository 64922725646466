import React, { useState, useRef, useEffect } from "react"
import clsx from "clsx"
import CheckBox from "../../../../components/ui/CheckBox/CheckBox/CheckBox"
import Box from "../../../../components/ui/Box/Box"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonColor,
  ButtonType,
} from "../../../../components/utils/enums"
import Button from "../../../../components/ui/Button/Button"
import ContextMenu from "../../../../components/ui/ContextMenu/ContextMenu"
import { NavOption } from "../../../../components/ui/NavBar/NavBar"
import Tooltip from "../../../../components/ui/Tooltip/Tooltip"
import { Option } from "../../../../framework/types/types"

import styles from "./ListItem.module.scss"

type ListItemProps = {
  className?: string
  profileName?: string
  industry?: string
  totalAdSpend?: number
  profileStatus?: string
  profileCompetition?: number
  linkedAdwordsAccount?: string
  campaignStatus?: string
  options: NavOption[]
  onAction: (name: Option) => void
  checked?: boolean
  onChange?: () => void
}

const ListItem: React.FC<ListItemProps> = ({
  className,
  profileName,
  industry,
  totalAdSpend,
  profileStatus,
  profileCompetition,
  linkedAdwordsAccount,
  campaignStatus: compaignStatus,
  options,
  onAction,
  checked,
  onChange,
}) => {
  const rootNode = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setIsOpen(false)
  }

  const handleOpenMenu = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return (
    <Box
      className={clsx(styles.root, className)}
      padding="8px 19px 8px 8px"
      ref={rootNode}
    >
      <CheckBox checked={checked} onChange={onChange} />

      <div className={styles.wrapper}>
        <Tooltip text={profileName} type={TextType.h5}>
          <Typography
            type={TextType.h5}
            color={TextColorType.dark}
            className={styles.clickable}
            onClick={() => onAction(options[0])}
          >
            {profileName}
          </Typography>
        </Tooltip>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {industry}
        </Typography>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {`$${totalAdSpend}`}
        </Typography>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {profileStatus}
        </Typography>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {profileCompetition}
        </Typography>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {linkedAdwordsAccount}
        </Typography>
        <Typography type={TextType.h5} color={TextColorType.dark}>
          {compaignStatus}
        </Typography>
        <Button
          color={ButtonColor.secondary}
          startIcon={{ name: "three-dots" }}
          variant={ButtonType.ghost}
          onClick={handleOpenMenu}
          className={isOpen ? styles.active : ""}
        />
      </div>

      {isOpen && (
        <ContextMenu
          options={options}
          onClick={onAction}
          className={styles.contextMenu}
        />
      )}
    </Box>
  )
}

export default ListItem
