/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react"
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom"
import Opportunities from "../Opportunities/Opportunities"
import Dashboard from "../Dashboard/Dashboard"
import PrivateLayout, {
  NavBarProps,
} from "../../layouts/PrivateLayout/PrivateLayout"
import { accountNavItems } from "../navOptions"
import { NavOption } from "../../components/ui/NavBar/NavBar"
import { useStore } from "../../store"
import CreateCampaign from "../CreateCampaign/CreateCampaign"

const defNav = (navItemsList: NavOption[], pathname: string) => (): NavOption =>
  navItemsList.find((item) => pathname.endsWith(item.value)) ?? navItemsList[0]

const AccountRoot: React.FC = () => {
  const history = useHistory()
  const { accountStore } = useStore()
  const { loadAccount, setAccountId } = accountStore

  const [tab, setTab] = useState<NavOption>()

  const { id } = useParams<any>()
  const { pathname } = useLocation<any>()

  useEffect(() => {
    if (!id || Number.isNaN(id)) history.push("./")
  }, [id, history])

  useEffect(() => {
    if (id) {
      setAccountId(id)
    }
  }, [setAccountId, id])

  useEffect(() => {
    if (id) {
      loadAccount(id)
    }
  }, [id, loadAccount])

  useEffect(() => {
    setTab(defNav(accountNavItems, pathname))
  }, [pathname])

  const handleChangeTab = (item: NavOption) => {
    history.push(`.${item.value}`)
  }

  const withNavBar = (): NavBarProps | undefined => {
    const confirmation = pathname.endsWith("/create-campaign")

    if (!confirmation) {
      return {
        options: accountNavItems,
        title: "Campaign Setup",
        onSelect: handleChangeTab,
        currentTab: tab,
      }
    }
  }

  return (
    <PrivateLayout
      navBar={withNavBar()}
      onBack={() => history.push("/account-list")}
    >
      <Switch>
        <Route path="/account/:id/dashboard" component={Dashboard} />
        <Route path="/account/:id/opportunities" component={Opportunities} />
        <Route path="/account/:id/create-campaign" component={CreateCampaign} />
        <Route path="/account/:id/**">
          <Redirect to="./dashboard" />
        </Route>
        <Route path="/account/:id" exact>
          <Redirect to="/account" />
        </Route>
      </Switch>
    </PrivateLayout>
  )
}

export default AccountRoot
