/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import PublicLayout from "../../layouts/public/PublicLayout"
import TextField from "../../components/ui/TextField/TextField"
import Typography from "../../components/ui/Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonColor,
  ButtonType,
} from "../../components/utils/enums"
import Button from "../../components/ui/Button/Button"
import { validateEmail } from "../../components/utils/validators"
import styles from "./Login.module.scss"
import { useStore } from "../../store"

const Login = () => {
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [error, setError] = useState("")
  const history = useHistory()

  const { authStore } = useStore()
  const { isAuthorized, login } = authStore

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setEmail(value)
  }

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setPassword(value)
  }

  const handleSubmit = async () => {
    await login(email, password)
    history.push("/")
  }

  const handleRestorePassword = () => {
    history.push("/restore-password")
  }

  useEffect(() => {
    validateEmail(email) ? setError("") : setError("Incorrect email")
  }, [email])

  useEffect(() => {
    if (isAuthorized) history.push("/")
  }, [isAuthorized])

  return (
    <PublicLayout>
      <div className={styles.root}>
        <Typography type={TextType.h1} color={TextColorType.dark} bold>
          Sign In
        </Typography>
        <TextField
          label="Email"
          value={email}
          onChange={handleChangeEmail}
          error={error}
        />
        <TextField
          label="Password"
          type="password"
          value={password}
          onChange={handleChangePassword}
        />
        <div className={styles.control}>
          <Button
            variant={ButtonType.contained}
            color={ButtonColor.primary}
            onClick={handleSubmit}
          >
            Sign In
          </Button>
          <Button
            variant={ButtonType.ghost}
            color={ButtonColor.secondary}
            onClick={handleRestorePassword}
          >
            Forgot Password
          </Button>
        </div>
      </div>
    </PublicLayout>
  )
}

export default observer(Login)
