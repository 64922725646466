/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import Box from "../../../../components/ui/Box/Box"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import { recommendedKeywordsTableMapper } from "../../mock.temp"
import { TableBody, TableHeader } from "./Table/Table"
import TextField from "../../../../components/ui/TextField/TextField"
// import Pagination from "../../../../components/ui/Pagination/Pagination"
import { useStore } from "../../../../store"
import { numberWithCommas } from "../../../../components/utils/numberUtils"
import { Sticky } from "../../../../components/ui/Sticky/Sticky"

import styles from "./RecommendedKeywords.module.scss"

type RecommendedKeywordsProps = {}

const RecommendedKeywords: React.FC<RecommendedKeywordsProps> = observer(() => {
  const { opportunitiesStore, accountStore } = useStore()
  const { accountId, account } = accountStore
  const {
    sortBy,
    setSortBy,
    switchKeyword,
    getRecommendedKeywords,
    calculatorStore,
    totalSearchVolumeAvailable,
  } = opportunitiesStore

  const { ctr } = opportunitiesStore.calculatorStore

  useEffect(() => {
    if (accountId) {
      opportunitiesStore.loadRecommendedKeywords(accountId)
    }
  }, [accountId])

  useEffect(() => {
    if (account) {
      calculatorStore.parseAccountData(account)
    }
  }, [account, calculatorStore])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    opportunitiesStore.setSearchFilter(value)
  }

  return (
    <Box padding="0" height="100%" className={styles.root}>
      <Sticky
        topOffset={-180}
        stickyStyle={{ top: "180px", background: "white", zIndex: 5 }}
      >
        <div className={styles.sticky}>
          <div className={styles.header}>
            <Typography type={TextType.h1} color={TextColorType.dark}>
              Choose Recommended Keywords
            </Typography>
            <TextField placeholder="Search" withIcon onChange={handleChange} />
          </div>
          <div className={styles.card}>
            <Typography type={TextType.h3} color={TextColorType.dark}>
              Total Search Volume Available
            </Typography>
            <Typography type={TextType.h2} color={TextColorType.primary} bold>
              {numberWithCommas(totalSearchVolumeAvailable)}
            </Typography>
          </div>
          <TableHeader
            dataMapper={recommendedKeywordsTableMapper}
            onSort={setSortBy}
            sortBy={sortBy}
          />
        </div>
      </Sticky>
      <div className={styles.body}>
        <TableBody
          ctr={ctr}
          accountId={accountId}
          data={getRecommendedKeywords}
          onClick={switchKeyword}
        />
      </div>
      {/* <div className={styles.footer}>
          <Pagination currentPage={4} total={50} />
        </div> */}
    </Box>
  )
})

export default RecommendedKeywords
