import React, { useEffect } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import Box from "../../../../components/ui/Box/Box"
import Button from "../../../../components/ui/Button/Button"
import Insight from "../Insight/Insight"
import CampaignSegmentation from "../CampaignSegmentation/CampaignSegmentationTable"
import CampaignKPI from "../CompaignKPI/CampaignKPI"
import { useStore } from "../../../../store"

import styles from "./CampaignPerformance.module.scss"

type CampaignPerformanceProps = {
  className?: string
}

const CampaignPerformance: React.FC<CampaignPerformanceProps> = observer(
  ({ className }) => {
    const history = useHistory()

    const { accountDashboardStore, accountStore } = useStore()

    const {
      loadPerformanceSegmentedReport,
      currPeriodFrom,
    } = accountDashboardStore
    const { accountId } = accountStore

    useEffect(() => {
      if (accountId) loadPerformanceSegmentedReport(accountId)
    }, [loadPerformanceSegmentedReport, accountId, currPeriodFrom])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type={TextType.h1} color={TextColorType.primary} bold>
          Campaign Performance
        </Typography>
        <Box padding="36px" className={styles.body}>
          {/* /// Campaign Performance /// */}
          <div className={styles.section}>
            <div className={styles.header}>
              <Typography type={TextType.h1} bold>
                Campaign Insight and Context
              </Typography>
              <div className={styles.subtitle}>
                <Typography type={TextType.h6} color={TextColorType.gray}>
                  % Change vs. Previous Period
                </Typography>
                <Typography type={TextType.subtitle}>
                  Is this Insight Helpful?
                </Typography>
              </div>
            </div>
            <Insight />
            <div className={styles.footer}>
              <Button
                variant={ButtonType.contained}
                color={ButtonColor.primary}
                onClick={() => history.push("./opportunities")}
              >
                See more opportunities
              </Button>
            </div>
          </div>

          {/* /// Campaign KPIs /// */}
          <div className={styles.section}>
            <div className={styles.header}>
              <Typography className={styles.title} type={TextType.h1} bold>
                Campaign KPIs
              </Typography>
            </div>
            <CampaignKPI />
            <div className={styles.footer} />
          </div>

          {/* /// Campaign Segmentation /// */}
          <div className={styles.section}>
            <div className={styles.header}>
              <Typography className={styles.title} type={TextType.h1} bold>
                Campaign Segmentation
              </Typography>
            </div>
            <CampaignSegmentation />
            <div className={styles.footer}>
              <Button
                variant={ButtonType.contained}
                color={ButtonColor.primary}
              >
                View Campaigns
              </Button>
            </div>
          </div>
        </Box>
      </div>
    )
  }
)

export default CampaignPerformance
