import React, { ReactNode } from "react"
import styles from "./KPIsWidget.module.scss"
import { TextColorType, TextType } from "../../utils/enums"
import Typography from "../Typography/Typography"
import Box from "../Box/Box"

export type DataItems = {
  items: {
    name: string
    amount: number | string
    percent: number
  }[]
}[]

type KPIsWidgetProps = {
  title: string
  className?: string
  children: ReactNode
  subTitle: string
}

const KPIsWidget: React.FC<KPIsWidgetProps> = ({
  className,
  children,
  title,
  subTitle,
}) => (
  <Box width="100%" padding="13px 20px 18px 20px" className={className}>
    <div className={styles.dataWrapper}>
      <div className={styles.header}>
        <Typography type={TextType.h2} color={TextColorType.dark} bold>
          {title}
        </Typography>
        <Typography type={TextType.h6} color={TextColorType.gray}>
          {subTitle}
        </Typography>
      </div>
      <div className={styles.wrapper}>{children}</div>
    </div>
  </Box>
)

export default KPIsWidget
