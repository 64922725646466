import React, { useMemo, useState } from "react"
import { Line } from "react-chartjs-2"
import AutoSizer, { Size } from "react-virtualized-auto-sizer"
import clsx from "clsx"
import { options } from "./config"
import { NULL } from "../utils"

import styles from "./AreaChart.module.scss"

export type AreaChartProps = {
  className?: string
  data: number[]
}

const createRadialGradient = (canvas: any, { height, width }: Size) => {
  const ctx = canvas.getContext("2d")
  const gradient = ctx.createRadialGradient(
    width / 2,
    -height / 8,
    0,
    width / 2,
    height / 8,
    height * 1.05
  )
  gradient.addColorStop(0, "#17899eFF")
  gradient.addColorStop(1, "#5cbd9f00")
  return gradient
}

const createLinearGradient = (canvas: any, { height }: Size) => {
  const ctx = canvas.getContext("2d")
  const gradient = ctx.createLinearGradient(0, height, 0, 0)
  gradient.addColorStop(0, "#17899e")
  gradient.addColorStop(1, "#5cbd9f")
  return gradient
}

const chartDataProvider = (size: Size, data: number[], canvas: any) => ({
  labels: data.map((_, idx) => idx),
  datasets: [
    {
      data,
      borderColor: createLinearGradient(canvas, size),
      borderWidth: 2,
      backgroundColor: createRadialGradient(canvas, size),
    },
  ],
})

const AreaChart: React.FC<AreaChartProps> = ({ data, className }) => {
  const [size, setsize] = useState<Size>({ height: 0, width: 0 })

  const dataProvider = useMemo(() => chartDataProvider.bind(null, size, data), [
    data,
    size,
  ])

  return (
    <div className={clsx(styles.root, className)}>
      <AutoSizer onResize={setsize}>{() => null}</AutoSizer>
      <Line data={dataProvider} options={options} height={NULL} width={NULL} />
    </div>
  )
}

export default AreaChart
