/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styles from "./CreateCampaign.module.scss"
import BuildingAccount from "./components/BuildingAccount/BuildingAccount"
import SuccessfulCreated from "./components/SuccessfulCreated/SuccessfulCreated"
import GoogleAdwordsLink from "./components/GoogleAdwordsLink/GoogleAdwordsLink"
import FinalSuccess from "./components/FinalSuccess/FinalSuccess"

export type TabNames =
  | "Building Campaign"
  | "Successful Created"
  | "Google Adwords Link"
  | "Final Success"

const CreateCampaign = observer(() => {
  const [tab, setTab] = useState<TabNames>("Building Campaign")

  const handleSwitchTab = (tabName: TabNames) => {
    setTab(tabName)
  }

  return (
    <div className={styles.root}>
      <div className={styles.tabsWrapper}>
        {tab === "Building Campaign" && (
          <BuildingAccount switchTab={handleSwitchTab} />
        )}
        {tab === "Successful Created" && (
          <SuccessfulCreated switchTab={handleSwitchTab} />
        )}
        {tab === "Google Adwords Link" && (
          <GoogleAdwordsLink switchTab={handleSwitchTab} />
        )}
        {tab === "Final Success" && <FinalSuccess />}
      </div>
    </div>
  )
})

export default CreateCampaign
