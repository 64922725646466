/* eslint-disable prettier/prettier */
import { TextColorType, TextType } from "../../components/utils/enums"
import { numberWithCommas } from "../../components/utils/numberUtils"
import { AccountData } from "../../framework/types/account"
import { TableMapper } from "./components/RecommendedKeywords/Table/Table"
import { DatasetType, TableMapperType } from "./components/Table/Table"

const mbNoData = (value: any) => value || "-"

const parseAddress = (data: AccountData) =>
  data.street ||
    data.city ||
    data.state ||
    data.postal
    ? `${withComaOrNone(data.street)} ${withComaOrNone(data.city)} ${data.state} ${data.postal}`
    : ''

const withComaOrNone = (value?: string | number) => (value ? `${value},` : "")

export const recommendedKeywordsTableMapper: TableMapper[] = [
  { value: "keyword", label: "Keywords", sortable: true },
  {
    value: "avgSearchVolume",
    label: "Avg monthly search volume",
    sortable: true
  },
  {
    value: "graphPoints",
    label: "Avg monthly search volume trend",
  },
  { value: "estimate", label: "Estimated Monthly Ad Spend", sortable: true },
  { value: "distanceTo", label: "Distance To (miles) ", sortable: true },
  { value: "relevance", label: "Relevance Score" },
  { value: "action", label: "" },
]

export const opportunitiesTableMapper: TableMapperType[] = [
  {
    name: "name",
    label: "Name",
    renderCallback: mbNoData,
  },
  {
    name: "address",
    label: "Address",
    renderCallback: mbNoData,
    getValueCallback: parseAddress,
  },
  {
    name: "Industry.name",
    label: "Industry",
    renderCallback: mbNoData,
    style: { flex: "0.6 0.6" },
  },
  { name: "website", label: "Website", renderCallback: mbNoData },
  {
    name: "market.name",
    label: "Market",
    renderCallback: mbNoData,
    style: { flex: "0.6 0.6" },
  },
  {
    name: "monthlyAdBudget",
    label: "Total Contract Ad Spend",
    textStyle: { color: TextColorType.darkBlue, type: TextType.h1, bold: true },
    renderCallback: (value: number) =>
      `$${numberWithCommas(value ? value * 12 : 0)}`,
    style: { flex: "0.8 0.8" },
  },
]

export const opportunitiesTableDataMock: DatasetType[] = [
  {
    name: "Hotel Five",
    adress: "8212 Barton Club Dr \n Austin, TX \n 78735",
    industry: "Hotel",
    website: "www.hotel.com ",
    market: "Austin, TX",
    totalSpend: "$120,000",
  },
]

const defaultFormatter = (value: number) => numberWithCommas(value)
const defaultMoneyFormatter = (value: number) => `$${numberWithCommas(value, 2)}`

export const calculatorTableMapper: TableMapperType[] = [
  {
    name: "mySearchVolume",
    label: "MY SEARCH VOLUME",
    renderCallback: defaultFormatter,
    textStyle: {
      color: TextColorType.primary,
      type: TextType.h2,
      bold: true,
    },
    withBg: true,
  },
  { name: "avgCpc", label: "AVG.CPC", renderCallback: defaultMoneyFormatter },
  { name: "ctr", label: "CTR %", renderCallback: (value: number) => `${numberWithCommas(value, 2)}%` },
  { name: "clicks", label: "CLICKS", renderCallback: defaultFormatter },
  { name: "estimated", label: "ESTIMATED COST", renderCallback: defaultMoneyFormatter },
  { name: "revenue", label: "Revenue", renderCallback: defaultMoneyFormatter },
  { name: "roas", label: "ROAS", renderCallback: defaultMoneyFormatter },
  {
    name: "actualBudget",
    label: "ACTUAL BUDGET",
    renderCallback: (value: number) => `$${numberWithCommas(value)}`
  },
  {
    name: "adBudgetDifference",
    label: "AD BUDGET DIFFERENCE",
    renderCallback: defaultMoneyFormatter,
  },
]
