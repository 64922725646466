/* eslint-disable react/no-array-index-key */
import React, { useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import Typography from "../../../../components/ui/Typography/Typography"

import { Icon } from "../../../../components/ui/Icon/Icon"
import { TextType } from "../../../../components/utils/enums"
import Box from "../../../../components/ui/Box/Box"
import { useStore } from "../../../../store"
import { numberWithCommas } from "../../../../components/utils/numberUtils"
import NoData from "../../../../components/ui/NoData/NoData"

import styles from "./Insight.module.scss"

export type InsightOption = {
  id: string
  text: string
  value: number
  liked: boolean
}

export type InsightProps = {
  className?: string
}

export const Insight: React.FC<InsightProps> = observer(({ className }) => {
  const { accountDashboardStore } = useStore()
  const {
    insightList,
    loadingSegmentedPerformance: loading,
  } = accountDashboardStore

  // FIXME temp
  const [likes, setLike] = useState<string[]>([])

  const handleLike = (id: string) => {
    setLike((list) =>
      list.includes(id) ? list.filter((item) => item !== id) : [...list, id]
    )
  }

  return (
    <div>
      <Box
        className={clsx(styles.root, className)}
        width="100%"
        padding="10px 20px"
      >
        <div className={styles.body}>
          {insightList
            ? insightList.map(({ id, text, value }, idx) => {
                const formatted = numberWithCommas(value)
                const liked = likes.includes(id)
                return (
                  <div className={styles.row} key={id}>
                    <Typography
                      className={styles.text}
                      type={TextType.subtitle}
                    >
                      <span>{idx + 1}.</span>
                      <span className={styles.value}>{formatted}%</span>
                      <span className={styles.arrow}>
                        <Icon
                          name="arrow-down"
                          className={value > 0 ? styles.up : ""}
                          rotateAngle={value > 0 ? 180 : 0}
                        />
                      </span>
                      <span>{text}</span>
                    </Typography>
                    <span className={styles.like}>
                      <Icon
                        onClick={() => handleLike(id)}
                        name={!liked ? "like" : "like-cantained"}
                        primary={liked}
                      />
                    </span>
                  </div>
                )
              })
            : !loading && <NoData />}
        </div>
      </Box>
    </div>
  )
})

export default Insight
