/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react"
import clsx from "clsx"

import styles from "./Icon.module.scss"

export type IconName =
  | "download"
  | "done"
  | "plus"
  | "three-dots"
  | "building"
  | "settings"
  | "statistics"
  | "user"
  | "arrow-down"
  | "arrow-right"
  | "bag"
  | "bell"
  | "calendar"
  | "checkbook"
  | "cross"
  | "dashboard"
  | "file"
  | "hummer"
  | "like"
  | "like-cantained"
  | "long-arrow-right"
  | "megaphone"
  | "pen"
  | "search"
  | "star"
  | "trash"
  | "none"

export type IconProps = {
  name: IconName
  className?: string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  style?: React.CSSProperties
  primary?: boolean
  circle?: boolean
  rotateAngle?: number
}

export const Icon: React.FC<IconProps> = ({
  name,
  className,
  onClick,
  style,
  primary,
  circle,
  rotateAngle: rotateDeg = 0,
}) => (
  <i
    style={{ transform: `rotateZ(${rotateDeg}deg)`, ...style }}
    onClick={onClick}
    className={clsx(styles.icon, styles[`icon-${name}`], className, {
      [styles.circle]: circle,
      [styles.gradient]: primary,
    })}
  />
)
