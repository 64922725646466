/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React from "react"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import styles from "./FinalSuccess.module.scss"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import Button from "../../../../components/ui/Button/Button"

const GoogleAdwordsLink = observer(() => {
  const history = useHistory()

  const handleClick = () => {
    history.replace(`./dashboard`)
  }

  return (
    <div className={styles.root}>
      <Typography
        type={TextType.h2}
        color={TextColorType.primary}
        bold
        className={styles.title}
      >
        {`X Property Campaigns are \n Successfully Added in Google Adwords!`}
      </Typography>
      <div className={styles.buttonsWrapper}>
        <Button
          variant={ButtonType.contained}
          color={ButtonColor.primary}
          onClick={handleClick}
        >
          View Account campaigns
        </Button>
      </div>
    </div>
  )
})

export default GoogleAdwordsLink
