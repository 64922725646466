import { AxiosResponse } from "axios"
import HttpService from "./http.service"
import config from "../config"

import { KeywordEntity } from "../store/opportunities/opportunities.transformer"
import {
  AccountData,
  MonthlyPerformance,
  PerformanceMetrics,
  PerformanceSegmentType,
  SegmentedMetric,
} from "../framework/types/account"

export interface AccountResponse {
  data: any
}

export interface AccountDataResponse {
  data: AccountData
}

export interface KeywordsResponse {
  data: {
    records: KeywordEntity[]
  }
}

export interface PerformanceReportResponse {
  data: PerformanceMetrics
}

export type MonthlyPerformanceResponse = {} & MonthlyPerformance

export interface MonthlyPerformanceReportResponse {
  data: MonthlyPerformance[]
}

export type PerformanceSegmentReportResponseData = {
  [key in PerformanceSegmentType]: SegmentedMetric
}

export interface PerformanceSegmentReportResponse {
  data: PerformanceSegmentReportResponseData
}

class AccountAPI extends HttpService {
  fetchAccountList = (
    sort: "DESC" | "ASC" = "ASC"
  ): Promise<AxiosResponse<AccountResponse>> =>
    this.get(`properties/list`, { sort })

  fetchAccountAttributes = (
    id: string
  ): Promise<AxiosResponse<AccountResponse>> =>
    this.get(`property/${id}/keywords/stats`)

  getAccount = (id: number): Promise<AxiosResponse<AccountDataResponse>> =>
    this.get(`property/${id}`)

  getKeywords = (accountId: number): Promise<AxiosResponse<KeywordsResponse>> =>
    this.get(`property/${accountId}/attributes`, {
      statsOnly: 1,
      monthlyVolume: 1,
      limit: 99999,
    })

  getPerformance = (
    accountId: number,
    filter: { from: string; to: string; compare: boolean }
  ): Promise<AxiosResponse<PerformanceReportResponse>> =>
    this.get(`property/${accountId}/performance-report`, filter)

  switchKeyword = (
    accountId: number,
    filter: { attributeId: number; active: boolean }
  ): Promise<AxiosResponse<PerformanceReportResponse>> =>
    this.put(`property/${accountId}/attribute`, filter)

  getMonthlyPerformanceReport = (
    accountId: number,
    filter: { from: string; to: string; compare: boolean }
  ): Promise<AxiosResponse<MonthlyPerformanceReportResponse>> =>
    this.get(`property/${accountId}/performance-monthly-report`, filter)

  getPerformanceSegmentedReport = (
    accountId: number,
    filter: { from: string; to: string; compare: boolean }
  ): Promise<AxiosResponse<PerformanceSegmentReportResponse>> =>
    this.get(`property/${accountId}/performance-segmented-report`, filter)
}

export default new AccountAPI({
  API_URL: config.API_ROOT,
})
