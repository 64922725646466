import clsx from "clsx"
import React from "react"

import styles from "./Loader.module.scss"

export interface LoaderProps {
  isLoading?: boolean
  animationSpeed?: string
}

const Loader: React.FC<LoaderProps> = ({
  isLoading = true,
  animationSpeed = "8s",
}) => (
  <div
    className={clsx(styles.root, { [styles.visible]: isLoading })}
    style={{ animationDuration: animationSpeed }}
  />
)

export default Loader
