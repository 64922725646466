/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, computed, observable } from "mobx"
import { NumberFormatValues } from "react-number-format"
import _get from "lodash/get"
import {
  CalculatorDataIn,
  RecommendedKeyword,
} from "../../framework/types/opportunities"
import { AccountData } from "../../framework/types/account"

const mayBeEmptyNumber = (value: string, defValue: number = 0) =>
  value ? Number.parseFloat(value) : defValue
class Calculator {
  @observable selectedKeywords: RecommendedKeyword[] = []

  @observable aov: number = 0

  @observable monthlyAdBudget: number = 0

  @observable conversion: number = 0

  @observable avgCpc: number = 0

  @observable ctr: number = 0

  @action setValue(value: number = 0, name: keyof CalculatorDataIn) {
    this[name] = value
  }

  @action calculatorOnChange = (
    values: NumberFormatValues,
    name: keyof CalculatorDataIn
  ) => {
    const { floatValue } = values

    this.setValue(floatValue, name)
  }

  @action parseAccountData = async (account: AccountData) => {
    this.aov = mayBeEmptyNumber(account.aov)
    this.conversion = mayBeEmptyNumber(account.conversionRate)
    this.monthlyAdBudget = mayBeEmptyNumber(account.monthlyAdBudget)
    this.ctr = mayBeEmptyNumber(account.ctrGoal, 2)
  }

  @computed get mySearchVolume() {
    const { selectedKeywords } = this
    return selectedKeywords.reduce((accumulator, keyword) => {
      const val = accumulator + keyword.mySearchVolume
      return val
    }, 0)
  }

  @computed get calculatedAvgCpc() {
    const { selectedKeywords } = this
    const res = selectedKeywords.reduce(
      (accumulator, keyword) => accumulator + keyword.avgCpc,
      0
    )
    const cpc = res ? res / selectedKeywords.length : 0
    return Number(cpc.toFixed(2))
  }

  @computed get clicks() {
    const { mySearchVolume, ctr } = this
    return mySearchVolume * (ctr / 100)
  }

  @computed get estimated() {
    const { clicks, avgCpc } = this
    return clicks * avgCpc
  }

  @computed get revenue() {
    const { clicks, conversion, aov } = this
    return clicks * (conversion / 100) * aov
  }

  @computed get roas() {
    const { revenue, estimated } = this
    const res = revenue / estimated
    return !Number.isNaN(res) ? res : 0
  }

  @computed get actualBudget() {
    const { monthlyAdBudget } = this
    return monthlyAdBudget || 0
  }

  @computed get adBudgetDifference() {
    const { actualBudget, estimated } = this
    return actualBudget - estimated
  }
}

export default Calculator
