/* eslint-disable react/no-array-index-key */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import TableRowBox, { TableRow } from "./Table"
import { accountTableMapper } from "./MockData"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import { useStore } from "../../../../store"
import Loader from "../../../../components/ui/Loader/Loader"

import styles from "./CampaignSegmentationTable.module.scss"
import NoData from "../../../../components/ui/NoData/NoData"

type CampaignSegmentationProps = {
  className?: string
  withDetails?: boolean
}

const CampaignSegmentation: React.FC<CampaignSegmentationProps> = observer(
  ({ className, withDetails = true }) => {
    const { accountDashboardStore } = useStore()

    const {
      performanceSegmented,
      loadingSegmentedPerformance: loading,
    } = accountDashboardStore

    return (
      <div className={clsx(styles.root, className)}>
        <div
          className={clsx(styles.header, {
            [styles.withDetails]: withDetails,
          })}
        >
          <Typography
            key="title"
            className={styles.item}
            type={TextType.h6}
            color={TextColorType.gray}
          />
          {accountTableMapper.map(({ label }, idx) => (
            <Typography
              key={idx}
              className={styles.item}
              type={TextType.h6}
              color={TextColorType.gray}
            >
              {label.toUpperCase()}
            </Typography>
          ))}
        </div>
        <div className={styles.bodyWrapper}>
          <Loader isLoading={loading} />
          {performanceSegmented
            ? performanceSegmented.map((report) => (
                <TableRowBox
                  title={report.Name}
                  withDetails={withDetails}
                  dataset={report}
                  mapper={accountTableMapper}
                >
                  {report.AdGroups?.map((rowItem) => (
                    <TableRow
                      dataset={rowItem}
                      mapper={accountTableMapper}
                      title={rowItem.Name}
                    />
                  )) ?? null}
                </TableRowBox>
              ))
            : !loading && <NoData />}
        </div>
      </div>
    )
  }
)

export default CampaignSegmentation
