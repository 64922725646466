/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react"
import { ChartData, Line } from "react-chartjs-2"
import chartjs from "chart.js"
import { darkColors, NULL } from "../utils"
import getOptions from "./config"
import LegendList from "../Legends/LegendList"
import Box from "../../Box/Box"
import Loader from "../../Loader/Loader"

import styles from "./LineChart.module.scss"

export interface DataSetType {
  key: string
  yAxisID: string
  label: string
  data: number[]
}

export type LineDataType = {
  dashed?: boolean
  dataset: DataSetType[]
}

export type LineChartProps = {
  className?: string
  lineData: LineDataType[]
  labels: string[]
  colors?: string[]
  isLoading?: boolean
}

export const percentDataTransform = (data: number[], label: string) =>
  ["Ctr", "Res. Click %", "Conversion %"].find((value) => label.includes(value))
    ? data.map((value) => value * 100)
    : data

const chartDataProvider = (
  labels: string[],
  data: LineDataType[],
  colors: string[]
): ChartData<chartjs.ChartData> => ({
  labels,
  datasets: data.reduce<any>(
    (acc, { dataset, dashed }) => [
      ...acc,
      ...dataset.map(({ label, data, key, yAxisID }, idx) => ({
        data: percentDataTransform(data, label),
        label,
        key,
        yAxisID,
        borderColor: colors[idx],
        backgroundColor: "transparent",
        borderWidth: 2,
        ...(dashed ? { borderDash: [10], borderDashOffset: 10 } : {}),
      })),
    ],
    []
  ),
})

const LineChart: React.FC<LineChartProps> = ({
  lineData: dataset,
  labels,
  colors = darkColors,
  isLoading = false,
}) => {
  const data: any = useMemo(() => chartDataProvider(labels, dataset, colors), [
    dataset,
    labels,
    colors,
  ])

  return (
    <Box className={styles.root} padding="10px 30px">
      <div className={styles.legendContainer}>
        {dataset.map(({ dataset: data, dashed }, idx) => (
          <LegendList items={data} colors={colors} dashed={dashed} key={idx} />
        ))}
      </div>
      <div className={styles.chartContainer}>
        <Loader isLoading={isLoading} />
        <div className={styles.chart}>
          <Line
            data={data}
            options={getOptions(dataset)}
            height={NULL}
            width={NULL}
          />
        </div>
      </div>
    </Box>
  )
}

export default LineChart
