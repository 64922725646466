import { observable, action } from "mobx"
import clientService from "../../services/client.service"
import RootStore from "../RootStore"

export class ClientStore {
  @observable rootStore: RootStore

  @observable clients: any[] = []

  @observable checkedClient: number | null = null

  @observable loading: boolean = false

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @action loadClientList = async (id: number) => {
    this.loading = true
    const response = await clientService.fetchClientList(id)
    this.clients = response.data.data
    this.rootStore.agencyStore.setCheckedAgencyId(id)
    this.loading = false
  }

  @action setCheckedClient(id: number) {
    this.checkedClient = id
  }
}

export default ClientStore
