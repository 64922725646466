/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./GoogleAdwordsLink.module.scss"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import Button from "../../../../components/ui/Button/Button"
import { TabNames } from "../../CreateCampaign"

type GoogleAdwordsLinkProps = {
  switchTab: (tabName: TabNames) => void
}

const GoogleAdwordsLink: React.FC<GoogleAdwordsLinkProps> = observer(
  ({ switchTab }) => {
    const handleClick = () => switchTab("Final Success")

    return (
      <div className={styles.root}>
        <Typography
          type={TextType.h2}
          color={TextColorType.primary}
          bold
          className={styles.title}
        >
          {`To Implement New Adwords Campaign, link Google \n Adwords Account`}
        </Typography>
        <img src="/images/googleAdwords.svg" alt="Google Adwords" />
        <div className={styles.buttonsWrapper}>
          <Button
            variant={ButtonType.contained}
            color={ButtonColor.primary}
            onClick={handleClick}
          >
            Link Account
          </Button>
        </div>
      </div>
    )
  }
)

export default GoogleAdwordsLink
