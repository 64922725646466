export enum QuadSizeType {
  tiny = "tiny",
  small = "small",
  medium = "medium",
  big = "big",
}

export enum OrientationType {
  horizontal = "horizontal",
  vertical = "vertical",
}

export enum TextType {
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  h6 = "h6",
  title = "title",
  subtitle = "subtitle",
  body1 = "body1",
  body2 = "body2",
}

export enum TextWeightType {
  bold = "bold",
  normal = "normal",
}

export enum TextColorType {
  primary = "primary",
  dark = "dark",
  light = "light",
  gray = "gray",
  red = "red",
  darkBlue = "darkBlue",
  mediumDarkBlue = "mediumDarkBlue",
  lightGreen = "lightGreen",
  lightGray = "lightGray",
}

export enum ButtonType {
  contained = "contained",
  outlined = "outlined",
  ghost = "ghost",
}

export enum ButtonColor {
  primary = "primary",
  secondary = "secondary",
}
