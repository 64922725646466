/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChartOptions } from "chart.js"
import { numberWithCommas } from "../../../utils/numberUtils"
import { LineDataType } from "./LineChart"

const getOptions = (dataset: LineDataType[]): ChartOptions => {
  const kpi1Data = dataset[0]?.dataset[0]
  const kpi2Data = dataset[0]?.dataset[1]
  const KPI1 = kpi1Data ? kpi1Data.key : ""
  const KPI2 = kpi2Data ? kpi2Data.key : ""
  const KPI1MaxValue = kpi1Data ? Math.max(...kpi1Data.data) : 0
  const KPI2MaxValue = kpi2Data ? Math.max(...kpi2Data.data) : 0

  const getMaxValue = (kpiName: string, maxValue: number): number => {
    if (kpiName === "Ctr" || kpiName === "Res. Click %" || kpiName === "Conversion %") return 100

    const roundedMaxValue = Math.round(maxValue)
    const n = Number(roundedMaxValue.toString().length - 1)
    let roundingValue = 1

    for (let i = 0; i < n; i += 1) {
      roundingValue *= 10
    }

    return Math.round((roundedMaxValue * 2) / roundingValue) * roundingValue
  }

  return {
    maintainAspectRatio: false,
    aspectRatio: 3,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        top: 15,
      },
    },
    elements: {
      point: {},
    },
    scales: {
      xAxes: [
        {
          ticks: {
            fontFamily: "'Poppins'",
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          id: "A",
          position: "left",
          ticks: {
            fontFamily: "'Poppins'",
            callback: KPI1 ? transformer[KPI1 as keyof Transformer] : () => null,
            padding: 10,
            min: 0,
            max: getMaxValue(KPI1, KPI1MaxValue),
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
        {
          position: "right",
          id: "B",
          ticks: {
            fontFamily: "'Poppins'",
            callback: KPI2 ? transformer[KPI2 as keyof Transformer] : () => null,
            padding: 10,
            min: 0,
            max: getMaxValue(KPI2, KPI2MaxValue),
          },
          gridLines: {
            drawBorder: false,
          },
        },
      ],
    },
    tooltips: {
      axis: "y",
      callbacks: {
        label: (item, data) => {
          const datasetItem: any = data.datasets?.[item.datasetIndex ?? 0]
          const { label, key } = datasetItem

          return `${label} ${transformer[key as keyof Transformer](Number.parseInt(item.value ?? "0", 10))}`
        },
      },
    },
  }
}

const transformer = {
  Clicks: (value: string | number) => `${numberWithCommas(value as number)}`,
  Ctr: (value: string | number) => `${numberWithCommas(value as number)}%`,
  Impressions: (value: string | number) => `${numberWithCommas(value as number)}`,
  "Res. Click %": (value: string | number) => `${numberWithCommas(value as number)}%`,
  "Conversion %": (value: string | number) => `${numberWithCommas(value as number)}%`,
  AOV: (value: string | number) => `$${numberWithCommas(value as number)}`,
  Revenue: (value: string | number) => `$${numberWithCommas(value as number)}`,
  ROAS: (value: string | number) => `$${numberWithCommas(value as number)}`,
}

type Transformer = typeof transformer

export default getOptions
