import { NavOption } from "../components/ui/NavBar/NavBar"

export const accountNavItems: NavOption[] = [
  {
    label: "Account Dashboard",
    icon: "dashboard",
    value: "/dashboard",
  },
  { label: "Opportunities", icon: "bag", value: "/opportunities" },
  {
    label: "Manage Campaign",
    icon: "megaphone",
    value: "/campaign",
    disabled: true,
  },
  { label: "Checkbook", icon: "checkbook", value: "/checkbox", disabled: true },
  { label: "Manage Profile", icon: "user", value: "/profile", disabled: true },
  { label: "Rules", icon: "hummer", value: "/rules", disabled: true },
]

export const navMenuItems: NavOption[] = [
  { label: "Account Dashboard", icon: "dashboard", value: "/dashboard" },
  { label: "Opportunities", icon: "bag", value: "/opportunities" },
  { label: "Manage Campaign", icon: "megaphone", value: "/campaign" },
  { label: "Checkbook", icon: "checkbook", value: "/checkbox" },
  { label: "Manage Profile", icon: "user", value: "/profile" },
  { label: "Rules", icon: "hummer", value: "/rules" },
]

export const accountListNavItems: NavOption[] = [
  { label: "Account List", icon: "file", value: "/account-list" },
  { label: "Dashboard", icon: "dashboard", value: "/dashboard" },
  { label: "Profile", icon: "user", value: "/profile" },
  { label: "Checkbook", icon: "checkbook", value: "/checkbox" },
]
