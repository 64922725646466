import clsx from "clsx"
import React from "react"
import { Icon } from "../../../../components/ui/Icon/Icon"
import styles from "./Notifications.module.scss"

export interface NotificationsProps {}

export const Notifications: React.FC<NotificationsProps> = () => (
  <div className={clsx(styles.root, styles.gotSome)}>
    <Icon name="bell" primary />
  </div>
)

export default Notifications
