/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import Typography from "../Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonColor,
  ButtonType,
  OrientationType,
  QuadSizeType,
} from "../../utils/enums"
import IndicatedProgressBar from "../ProgressBar/IndicatedProgressBar/IndicatedProgressBar"
import Button from "../Button/Button"
import { numberWithCommas } from "../../utils/numberUtils"
import { useStore } from "../../../store"

import styles from "./MonthAdSpendChart.module.scss"
import NoData from "../NoData/NoData"

type MonthAdSpendChartProps = {
  date: number
  className?: string
  onClick?: () => void
}

const MonthAdSpendChart: React.FC<MonthAdSpendChartProps> = observer(
  ({ onClick, className, date }) => {
    const { accountDashboardStore, accountStore } = useStore()
    const { currYearPerf } = accountDashboardStore
    const { account } = accountStore

    const { dayOfMonthLeft, monthName, daysInMonth } = useMemo(() => {
      const dayOfMonthLeft = Number.parseInt(moment(date).format("DD"), 10)
      const monthName = moment(date).format("MMMM YYYY")
      const daysInMonth = moment(date).daysInMonth()
      return { dayOfMonthLeft, monthName, daysInMonth }
    }, [date])

    const latestMonthPerformance = useMemo(() => {
      if (currYearPerf) {
        const lastIndex = currYearPerf.length - 1
        return currYearPerf[lastIndex]
      }
      return null
    }, [currYearPerf])

    const data = useMemo(() => {
      if (account && latestMonthPerformance) {
        const totalBudget = Number.parseFloat(account.monthlyAdBudget)
        const daysLeftPercent = dayOfMonthLeft / daysInMonth
        const currentSpend = latestMonthPerformance.Metrics.Cost.Value

        const spendPerDay = currentSpend / daysLeftPercent
        const currentPercent = (spendPerDay / totalBudget) * 100
        return {
          currentSpend,
          totalBudget,
          currentPercent,
          daysLeftPercent,
        }
      }
      return null
    }, [account, latestMonthPerformance, dayOfMonthLeft, daysInMonth])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type={TextType.h2} color={TextColorType.dark} bold>
          This Month's Ad Spend
        </Typography>
        <Typography type={TextType.h6} color={TextColorType.gray} upperCase>
          ({monthName})
        </Typography>

        {data && (
          <>
            <div className={styles.wrapper}>
              <div className={styles.section}>
                <div className={styles.leftSection}>
                  <Typography
                    type={TextType.h6}
                    color={TextColorType.gray}
                    upperCase
                  >
                    Current spend
                  </Typography>
                  <Typography
                    type={TextType.h2}
                    color={TextColorType.darkBlue}
                    bold
                  >
                    {data.currentSpend
                      ? `$ ${numberWithCommas(data.currentSpend)}`
                      : "N/A"}
                  </Typography>
                </div>

                <div className={styles.section}>
                  <div className={styles.leftSection}>
                    <Typography
                      type={TextType.h6}
                      color={TextColorType.gray}
                      upperCase
                    >
                      Monthly budget
                    </Typography>
                    <Typography type={TextType.h2} bold>
                      {data.totalBudget
                        ? `$ ${numberWithCommas(data.totalBudget)}`
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={styles.section}>
                <div className={styles.rightSection}>
                  <Typography
                    type={TextType.h6}
                    color={TextColorType.gray}
                    upperCase
                  >
                    Days Left in Month
                  </Typography>
                  <Typography
                    type={TextType.h2}
                    color={TextColorType.dark}
                    bold
                  >
                    {daysInMonth - dayOfMonthLeft}
                  </Typography>
                </div>
                <div className={styles.rightSection}>
                  <Typography
                    type={TextType.h6}
                    color={TextColorType.gray}
                    upperCase
                  >
                    Projected Spend
                  </Typography>
                  <Typography
                    type={TextType.h2}
                    color={TextColorType.dark}
                    bold
                  >
                    {data.currentPercent
                      ? `${numberWithCommas(data.currentPercent, 2)}%`
                      : "N/A"}
                  </Typography>
                </div>
              </div>
            </div>
            {data.totalBudget ? (
              <IndicatedProgressBar
                value={data.currentSpend}
                maxValue={data.totalBudget}
                gradient={OrientationType.vertical}
                size={QuadSizeType.medium}
                showValue
                onDrawValue={(value) => `$${value}`}
              />
            ) : (
              <NoData className={styles.noData} />
            )}
          </>
        )}

        <div className={styles.buttonWrapper}>
          <Button
            variant={ButtonType.ghost}
            color={ButtonColor.primary}
            fit
            onClick={onClick}
            endIcon={{ name: "long-arrow-right" }}
          >
            View Yearly Checkbook
          </Button>
        </div>
      </div>
    )
  }
)

export default MonthAdSpendChart
