/* eslint-disable react/no-array-index-key */
import React from "react"
import clsx from "clsx"
import styles from "./DataChangeWidget.module.scss"
import Typography from "../Typography/Typography"
import { TextColorType, TextType } from "../../utils/enums"
import Box from "../Box/Box"
import { Icon } from "../Icon/Icon"

type DataChangeWidgetProps = {
  className?: string
  title?: string
  options?: {
    amount: string
    trend: {
      value: number
      direction: number
    }
  }[]
}

const DataChangeWidget: React.FC<DataChangeWidgetProps> = ({
  className,
  title,
  options = [],
}) => (
  <Box className={clsx(styles.root, className)} padding="22px 20px 14px 29px">
    <Typography type={TextType.h2} color={TextColorType.dark} bold>
      {title}
    </Typography>

    <div className={styles.wrapper}>
      {options.map((data: any, idx) => {
        const { amount, trend } = data

        return (
          <div className={styles.item} key={idx}>
            <Typography type={TextType.h5} color={TextColorType.dark}>
              {amount}
            </Typography>
            <TrendValue {...trend} />
          </div>
        )
      })}
    </div>
  </Box>
)

export type TrendValueProps = {
  value: number
  typographyType?: TextType
}

export const TrendValue: React.FC<TrendValueProps> = ({
  value,
  typographyType = TextType.h5,
}) => (
  <Typography
    className={styles.trend}
    type={typographyType}
    color={value > 0 ? TextColorType.lightGreen : TextColorType.red}
  >
    <Icon
      name="arrow-down"
      className={value > 0 ? styles.upArrow : styles.downArrow}
      rotateAngle={value > 0 ? 180 : 0}
    />
    {`${value}%`}
  </Typography>
)

export default DataChangeWidget
