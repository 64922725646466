/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import clsx from "clsx"
import React, { CSSProperties } from "react"
import _get from "lodash/get"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"

import styles from "./Table.module.scss"

export type TableMapperType = {
  name: string
  label: string
  getValueCallback?: (data: any) => string | number
  renderCallback?: (value: any, name: any) => string
  textStyle?: {
    type?: TextType
    color?: TextColorType
    bold?: boolean
    className?: string
  }
  style?: CSSProperties
  className?: string
  withBg?: boolean
}

export type DatasetType = {
  [key: string]: number | string
}

export interface TableProps {
  dataMapper: TableMapperType[]
  data: any[]
}

export const Table: React.FC<TableProps> = ({ dataMapper, data }) => (
  <div className={styles.root}>
    <div className={styles.row}>
      {dataMapper.map(({ label, style }) => (
        <Typography
          style={style}
          className={styles.column}
          type={TextType.h6}
          color={TextColorType.gray}
          key={label}
        >
          {label.toUpperCase()}
        </Typography>
      ))}
    </div>
    <div className={styles.list}>
      {data.map((data, idx) => (
        <div className={styles.row} key={idx}>
          {dataMapper.map(
            ({
              name,
              textStyle,
              withBg,
              renderCallback,
              getValueCallback,
              style,
            }) => {
              const value = getValueCallback?.(data) ?? _get(data, name, null)
              const formatted = renderCallback?.(value, name) ?? value
              return (
                <span
                  className={clsx(styles.column, { [styles.widthBg]: withBg })}
                  style={style}
                >
                  <Typography
                    title={formatted}
                    className={styles.text}
                    type={TextType.h3}
                    {...textStyle}
                    key={name}
                  >
                    {formatted}
                  </Typography>
                </span>
              )
            }
          )}
        </div>
      ))}
    </div>
  </div>
)
