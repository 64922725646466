import clsx from "clsx"
import React, { createRef, ReactNode, useEffect, useState } from "react"
import { ITypographyProps, Typography } from "../Typography/Typography"
import styles from "./Tooltip.module.scss"

export interface TooltipProps extends ITypographyProps {
  text?: string
  children: ReactNode
  containerClassName?: string
  lineClamp?: number
}

export const Tooltip: React.FC<TooltipProps> = ({
  children,
  containerClassName,
  text,
  lineClamp = 1,
  ...rest
}: TooltipProps) => {
  const ref = createRef<HTMLDivElement>()
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(
      !!(
        (ref?.current?.scrollHeight ?? 0) > (ref?.current?.clientHeight ?? 0) ||
        (ref?.current?.scrollWidth ?? 0) > (ref?.current?.clientWidth ?? 0)
      ) && !!text
    )
  }, [ref?.current?.scrollWidth, ref?.current?.scrollHeight])

  return (
    <div
      className={clsx(
        styles.root,
        { [styles.active]: isVisible },
        containerClassName
      )}
    >
      <div
        ref={ref}
        className={styles.wrapper}
        style={{ WebkitLineClamp: lineClamp }}
      >
        {children}
      </div>
      <span className={styles.overlay}>
        <Typography {...rest}>{text}</Typography>
      </span>
    </div>
  )
}

export default Tooltip
