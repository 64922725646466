/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react"
import clsx from "clsx"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import CheckBox from "../../../../components/ui/CheckBox/CheckBox/CheckBox"
import styles from "./ColumnNames.module.scss"
import { Icon } from "../../../../components/ui/Icon/Icon"
import { Option } from "../../../../framework/types/types"
import { TableMapper } from "../../AccountList"

type ColumnNamesProps = {
  checked?: boolean
  mapper: TableMapper[]
  onChange?: () => void
  onSort?: (sortBy: { value: string; direction: boolean }) => void
}

const ColumnNames: React.FC<ColumnNamesProps> = ({
  checked = false,
  mapper,
  onChange,
  onSort,
}) => {
  const [sortBy, setSortBy] = useState(() => ({
    value: "none",
    direction: false,
  }))

  const handleSort = (item: Option) => {
    setSortBy((current) => {
      const newValue = {
        value: item.value.toString(),
        direction: current.value === item.value && !current.direction,
      }
      onSort?.(newValue)
      return newValue
    })
  }

  return (
    <div className={styles.root}>
      <CheckBox checked={checked} onChange={onChange} />

      <div className={styles.wrapper}>
        {mapper.map((option) => (
          <Typography
            onClick={option.isSortable ? () => handleSort(option) : undefined}
            className={clsx(styles.column, {
              [styles.sortable]: option.isSortable,
            })}
            type={TextType.h6}
            color={TextColorType.gray}
            upperCase
            key={option.value}
          >
            {option.label}
            <span className={styles.sortArrow}>
              {option.isSortable ? (
                <Icon
                  name={sortBy.value === option.value ? "arrow-down" : "none"}
                  rotateAngle={sortBy.direction ? 180 : 0}
                />
              ) : null}
            </span>
          </Typography>
        ))}
        <div className={styles.column}>
          <Typography
            className={styles.last}
            type={TextType.h6}
            color={TextColorType.gray}
            upperCase
          >
            Action
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default ColumnNames
