/* eslint-disable no-underscore-dangle */
import { observable, action, computed } from "mobx"
import { AUTH_TOKEN_KEY } from "../../framework/constants/auth"
import { UserData } from "../../framework/types/auth"
import authService from "../../services/auth.service"
// import { sha256 } from "../../services/utils"

export class AuthStore {
  @observable isTokenChecked: boolean = false

  @observable private _token: string | null = localStorage.getItem(
    AUTH_TOKEN_KEY
  )

  @observable user: UserData | null = null

  @observable inProgress: boolean = false

  get token() {
    return this._token
  }

  set token(newToken: string | null) {
    if (newToken) localStorage.setItem(AUTH_TOKEN_KEY, newToken)
    else localStorage.removeItem(AUTH_TOKEN_KEY)
    this._token = newToken
  }

  @computed get isAuthorized() {
    return !!this.user && !!this.isTokenChecked
  }

  @computed get fullName() {
    if (!this.user) return "No Name"
    const { Name, Surname } = this.user
    return `${Name} ${Surname}`
  }

  @action login = async (login: string, pass: string) => {
    this.inProgress = true
    try {
      // const passHash = await sha256(pass)
      const response = await authService.signIn(login, pass)

      this.user = response.data.data
      this.token = response.data.data.APIKey
    } catch (error) {
      console.log(error)
    }
    this.inProgress = false
    this.isTokenChecked = false
  }

  @action checkToken = async () => {
    this.inProgress = true
    try {
      if (this.token) {
        const { data: payload, status } = await authService.getUser()
        if (status === 200) {
          this.user = payload.data
          console.log(status, payload)
        }
      }
    } catch (error) {
      this.logout()
    }
    this.inProgress = false
    this.isTokenChecked = true
  }

  @action logout = () => {
    this.user = null
    this.token = null
  }
}

export default AuthStore
