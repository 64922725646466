import OpportunitiesStore from "./opportunities/opportunities"
import SideBarMenuStore from "./sideBarMenu/sideBarMenu"
import AccountDashboardStore from "./account-dashboard/account-dashboard"
import AuthStore from "./auth/auth"
import AgencyStore from "./agency/agency"
import ClientStore from "./client/client"
import AccountStore from "./account/account"

export default class RootStore {
  // Put all other stores here
  authStore = new AuthStore()

  agencyStore = new AgencyStore()

  accountStore = new AccountStore(this)

  clientStore = new ClientStore(this)

  opportunitiesStore = new OpportunitiesStore()

  sideBarMenuStore = new SideBarMenuStore(this)

  accountDashboardStore = new AccountDashboardStore()
}
