/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PublicLayout from "../../layouts/public/PublicLayout"
import TextField from "../../components/ui/TextField/TextField"
import Typography from "../../components/ui/Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonColor,
  ButtonType,
} from "../../components/utils/enums"
import Button from "../../components/ui/Button/Button"
import { validateEmail } from "../../components/utils/validators"
import styles from "./RestorePassword.module.scss"

const RestorePassword = () => {
  const [email, setEmail] = useState<string>("")
  const [error, setError] = useState("")
  const [submitted, setSubmitted] = useState<boolean>(false)
  const history = useHistory()

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target

    setEmail(value)
  }

  const handleSubmit = () => {
    setSubmitted(true)
  }

  const handleBack = () => {
    history.push("/login")
  }

  useEffect(() => {
    validateEmail(email) ? setError("") : setError("Incorrect email")
  }, [email])

  return (
    <PublicLayout>
      <div className={styles.root}>
        {!submitted ? (
          <div style={{ minWidth: "333px" }}>
            <Typography type={TextType.title} color={TextColorType.dark} bold>
              Restore Password
            </Typography>
            <TextField
              label="Email"
              value={email}
              onChange={handleChangeEmail}
              error={error}
            />
            <Button
              variant={ButtonType.contained}
              color={ButtonColor.primary}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </div>
        ) : (
          <div style={{ maxWidth: "580px" }}>
            <Typography type={TextType.title} color={TextColorType.dark} bold>
              The instructions were successfully sent
            </Typography>
            <Typography type={TextType.h3} color={TextColorType.dark}>
              We have sent you instructions on how to reset your password.
              Please, check your inbox.
            </Typography>
            <Button
              variant={ButtonType.contained}
              color={ButtonColor.primary}
              onClick={handleBack}
            >
              Back to Sign In Page
            </Button>
          </div>
        )}
      </div>
    </PublicLayout>
  )
}

export default RestorePassword
