import React, { PropsWithChildren, forwardRef } from "react"
import clsx from "clsx"
import styles from "./Box.module.scss"

interface IBoxProps {
  padding?: number | string
  width?: number | string
  height?: number | string
  className?: string
}

const Box = forwardRef<HTMLDivElement, PropsWithChildren<IBoxProps>>(
  (props, ref) => {
    const { children, padding, width, height, className } = props

    const boxStyle = {
      padding: padding || "10px",
      width: width || "auto",
      height: height || "auto",
    }
    return (
      <div className={clsx(styles.box, className)} style={boxStyle} ref={ref}>
        {children}
      </div>
    )
  }
)

export default Box
