import clsx from "clsx"
import React from "react"
import Button from "../../../../../components/ui/Button/Button"
import AreaChart from "../../../../../components/ui/Charts/AreaChart/AreaChart"
import Tooltip from "../../../../../components/ui/Tooltip/Tooltip"
import Typography from "../../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../../components/utils/enums"
import { numberWithCommas } from "../../../../../components/utils/numberUtils"
import { RecommendedKeyword } from "../../../../../framework/types/opportunities"
import { transformEstimate } from "../../../../../store/opportunities/opportunities.transformer"
import styles from "./Table.module.scss"

export interface RowProps extends RecommendedKeyword {
  onClick: (accountId: number, keywordId: number) => void
  ctr?: number
  accountId?: number
}

export const Row: React.FC<RowProps> = (props) => {
  const {
    id,
    name,
    graphPoints,
    avgSearchVolume,
    distance,
    relevanceScope,
    added,
    onClick,
    accountId,
    ctr,
  } = props
  const estimate = transformEstimate(props, ctr)

  return (
    <div className={styles.row}>
      <div className={styles.body}>
        <Tooltip
          lineClamp={2}
          text={name}
          type={TextType.h3}
          containerClassName={styles.column}
        >
          <Typography type={TextType.h3}>{name}</Typography>
        </Tooltip>
        <Typography className={styles.column} type={TextType.h3}>
          {numberWithCommas(avgSearchVolume)}
        </Typography>
        <div className={styles.column}>
          {graphPoints.length > 0 ? (
            <AreaChart
              data={graphPoints.slice()}
              className={styles.chartContainer}
            />
          ) : (
            <Typography
              className={styles.centered}
              type={TextType.h3}
              color={TextColorType.gray}
            >
              (No data)
            </Typography>
          )}
        </div>
        <Typography className={styles.column} type={TextType.h3}>
          {`$${numberWithCommas(estimate)}`}
        </Typography>
        <Typography className={styles.column} type={TextType.h3}>
          {relevanceScope}
        </Typography>
        <Typography className={styles.column} type={TextType.h3}>
          {numberWithCommas(distance)}
        </Typography>
        <div className={clsx(styles.buttonContainer, styles.column)}>
          <Button
            variant={added ? ButtonType.ghost : ButtonType.outlined}
            color={ButtonColor.primary}
            fluid
            onClick={() => accountId && onClick(accountId, id)}
          >
            {added ? "Remove" : "Add"}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Row
