import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import PublicLayout from "../../layouts/public/PublicLayout"
import TextField from "../../components/ui/TextField/TextField"
import Typography from "../../components/ui/Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonColor,
  ButtonType,
} from "../../components/utils/enums"
import Button from "../../components/ui/Button/Button"
import styles from "./NewPassword.module.scss"

const RestorePassword = () => {
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const history = useHistory()

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target

    setConfirmPassword(value)
  }

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target

    setNewPassword(value)
  }

  const handleSubmit = () => {
    history.push("/login")
  }

  return (
    <PublicLayout>
      <div className={styles.root}>
        <Typography type={TextType.title} color={TextColorType.dark} bold>
          Restore Password
        </Typography>
        <TextField
          label="Create new Password"
          value={newPassword}
          type="password"
          onChange={handleChangeNewPassword}
        />
        <TextField
          label="Repeat new Password"
          value={confirmPassword}
          type="password"
          onChange={handleChangeConfirmPassword}
        />
        <Button
          variant={ButtonType.contained}
          color={ButtonColor.primary}
          onClick={handleSubmit}
        >
          Send
        </Button>
      </div>
    </PublicLayout>
  )
}

export default RestorePassword
