import {
  randomNumber,
  randomNumbersArray,
} from "../../../../components/utils/numberUtils"

const KPI = randomNumbersArray(12, 1000000)

export const data = [
  { label: "2021", data: KPI.map((value) => randomNumber(value, 1000000)) },
  { label: "2020", data: KPI },
]

export const labels = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]
