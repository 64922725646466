import { AxiosResponse } from "axios"
import HttpService from "./http.service"
import config from "../config"

export interface ClientsResponse {
  data: any
}

class ClientsAPI extends HttpService {
  fetchClientList = (id: number): Promise<AxiosResponse<ClientsResponse>> =>
    this.get(`agency/${id}/clients`)
}

export default new ClientsAPI({
  API_URL: config.API_ROOT,
})
