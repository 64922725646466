import React, { useEffect, useMemo, useState } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import LineChart, {
  LineDataType,
} from "../../../../components/ui/Charts/LineChart/LineChart"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import ToggleButton from "../../../../components/ui/CheckBox/ToggleButton/ToggleButton"
import DropDown from "../../../../components/ui/DropDown/DropDown"
import { labels } from "./MockData"
import { darkColors } from "../../../../components/ui/Charts/utils"
import { KPIDropDownListMock } from "../../../../components/ui/KPIsWidget/mockData"
import { useStore } from "../../../../store"
import { MetricOption } from "../../../../store/account-dashboard/dataTransformers"

import styles from "./HistoricalKPITrends.module.scss"

type HistoricalKPITrendsProps = {
  className?: string
}

const HistoricalKPITrends: React.FC<HistoricalKPITrendsProps> = observer(
  ({ className }) => {
    const [doCompare, setCompare] = useState(false)
    const { accountDashboardStore, accountStore } = useStore()
    const { accountId } = accountStore
    const {
      currHistoricalKPI,
      prevHistoricalKPI,
      metrics,
      loadingYearPerformance,
      error,
      setMetric,
      loadYearPerformance,
    } = accountDashboardStore

    useEffect(() => {
      if (accountId) {
        loadYearPerformance(accountId)
      }
    }, [accountId, loadYearPerformance])

    const data = useMemo(() => {
      const dataset: LineDataType[] = []
      if (!error) {
        if (currHistoricalKPI) {
          dataset.push(currHistoricalKPI)
        }
        if (doCompare && prevHistoricalKPI) dataset.push(prevHistoricalKPI)
      }

      return dataset
    }, [doCompare, currHistoricalKPI, prevHistoricalKPI, error])

    return (
      <div className={clsx(styles.root, className)}>
        <div className={styles.wrapper}>
          <Typography type={TextType.h1} color={TextColorType.primary} bold>
            Historical KPI Trends
          </Typography>

          <div className={styles.controls}>
            <div className={styles.toggleWrapper}>
              <Typography
                type={TextType.h5}
                color={TextColorType.dark}
                bold
                upperCase
              >
                COMPARE TO PREVIOUS YEAR
              </Typography>

              <ToggleButton
                checked={doCompare}
                onChange={() => setCompare((value) => !value)}
              />
            </div>

            <div className={styles.dropDownsWrapper}>
              {metrics.map((metric, idx) => (
                <div>
                  <Typography
                    type={TextType.h4}
                    color={TextColorType.gray}
                    style={{ color: darkColors[0] }}
                  >
                    KPI {idx + 1}
                  </Typography>
                  <DropDown
                    options={KPIDropDownListMock}
                    onChange={(option) =>
                      setMetric(idx, option as MetricOption)
                    }
                    value={metric}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <LineChart
          lineData={data}
          labels={labels}
          colors={darkColors}
          isLoading={loadingYearPerformance}
        />
      </div>
    )
  }
)

export default HistoricalKPITrends
