/* eslint-disable no-useless-escape */
import _maxBy from "lodash/maxBy"
import _get from "lodash/get"
import {
  DataSetType as BarChartDataSetType,
  DataSetType,
} from "../../components/ui/Charts/BarChart/BarChart"
import {
  DataSetType as LineChartDataSetType,
  LineDataType,
} from "../../components/ui/Charts/LineChart/LineChart"
import {
  AccountData,
  MetricNameType,
  MonthlyPerformance,
  PerformanceMetrics,
  PerformanceSegmentedReportData,
  PerformanceSegmentType,
} from "../../framework/types/account"
import { Option } from "../../framework/types/types"
import { labels } from "../../pages/Dashboard/components/AdSpendBudget/MockData"
import { CampaignKPIDataType } from "../../pages/Dashboard/components/CampaignPerformance/MockData"
import { InsightOption } from "../../pages/Dashboard/components/Insight/Insight"
import { PerformanceSegmentReportResponseData } from "../../services/account.service"

export const transformValue = (value: number | string): number =>
  typeof value === "string" ? Number(value.replace(/([^0-9^\.])/g, "")) : value

export interface MetricOption extends Option {
  value: keyof PerformanceMetrics
}

export const transformDataset = (
  metric: MetricOption,
  year: string,
  performances: MonthlyPerformance[],
  idx: number
): LineChartDataSetType => ({
  key: metric.label,
  yAxisID: idx === 0 ? "A" : "B",
  label: `${metric.label} ${year}`,
  data: performances.map(
    (metricData) => metricData.Metrics[metric.value].Value
  ),
})

export const extractMonthlyKPI = (
  metrics: MetricOption[],
  performances: MonthlyPerformance[],
  year: string,
  dashed: boolean = false
): LineDataType => ({
  dashed,
  dataset: metrics.map((metric, idx) =>
    transformDataset(metric, year, performances, idx)
  ),
})

export const findTrend = (
  currentValue: number,
  previousValue: number
): number => {
  const delta = currentValue - previousValue
  const max = currentValue > previousValue ? currentValue : previousValue
  const percent = (delta / max) * 100
  return percent
}

export const extractMonthlyAdSpend = (
  performance: MonthlyPerformance[],
  year: string
): BarChartDataSetType => ({
  label: year,
  data: labels.map((_, idx) => performance[idx]?.Metrics?.Cost?.Value ?? 0),
})

const segmentMapper: PerformanceSegmentType[] = [
  "Awareness",
  "Consideration",
  "Conversion",
]

export const transformSegmentedReport = (
  report: PerformanceSegmentReportResponseData
): PerformanceSegmentedReportData[] =>
  segmentMapper.map((segName) => ({ ...report[segName], Name: segName }))

export const transformAccountToCampaignKPI = (
  metric: "ctrGoal" | "resClickGoal" | "roasGoal",
  account: AccountData
): DataSetType => ({
  label: "Goal",
  data: [account[metric] ? Number.parseFloat(account[metric]) : 0],
})

export const transformPerformanceToCampaignKPI = (
  metric: "Ctr" | "Clicks" | "Roas",
  performance: PerformanceMetrics
): DataSetType => ({
  label: "Current",
  data: [performance[metric]?.Value ?? 0],
})

export const transformCampaignKPIData = (
  account: AccountData,
  performance: PerformanceSegmentedReportData[]
): CampaignKPIDataType => ({
  ctr: [
    transformPerformanceToCampaignKPI("Ctr", performance[0]),
    transformAccountToCampaignKPI("ctrGoal", account),
  ],
  clicks: [
    transformPerformanceToCampaignKPI("Clicks", performance[1]),
    transformAccountToCampaignKPI("resClickGoal", account),
  ],
  roas: [
    transformPerformanceToCampaignKPI("Roas", performance[2]),
    transformAccountToCampaignKPI("roasGoal", account),
  ],
})

// Impressions, Clicks, CTR.%, Revenue, ROAS
export const InsightsMetricsList: MetricNameType[] = [
  "Impressions",
  "Clicks",
  "Ctr",
  "Revenue",
  "Roas",
]

const defMinChange = -9999999

export const transformInsights = (
  segments: PerformanceSegmentedReportData[],
  metric: MetricNameType
): InsightOption | null => {
  const metricPath = `${metric}.Change`
  const res = _maxBy(
    segments.map((segment) => ({
      segmentName: segment.Name,
      maxInSegment: _maxBy(segment.AdGroups, (item?: PerformanceMetrics) => {
        const value = _get(item, metricPath, defMinChange)
        return value > 0.99 ? defMinChange : value
      }),
    })),
    ({ maxInSegment }) => {
      const value = _get(maxInSegment, metricPath, defMinChange)
      return value > 0.99 ? defMinChange : value
    }
  )
  if (res?.maxInSegment) {
    return {
      id: metric,
      value: _get(res.maxInSegment, metricPath, 0) * 100,
      text: `in ${metric} for ${res.maxInSegment.Name} (${res.segmentName})`,
      liked: false,
    }
  }
  return null
}
