/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { action, observable, computed } from "mobx"
import RootStore from "../RootStore"

class SideBarMenuStore {
  @observable rootStore: RootStore

  @observable checkedMenu: string = ""

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @action setCheckedMenu(name: string) {
    this.checkedMenu = name
  }

  @computed get menuOptions() {
    const { clientStore, agencyStore, accountStore } = this.rootStore
    const menu = []

    const agencies = {
      name: "Agencies",
      isLoading: false,
      options: agencyStore.agencies,
    }

    menu.push(agencies)

    if (agencyStore.checkedAgency) {
      const clients = {
        name: "Clients",
        isLoading: clientStore.loading,
        options: clientStore.clients,
      }

      menu.push(clients)
    }

    if (clientStore.checkedClient && this.checkedMenu !== "Agencies") {
      const accounts = {
        name: "Accounts",
        isLoading: accountStore.loading,
        options: accountStore.accounts.filter(
          (item) => item.clientId === clientStore.checkedClient
        ),
      }

      menu.push(accounts)
    }

    return menu
  }
}

export default SideBarMenuStore
