import { ChartOptions } from "chart.js"

export type CallbackType = (
  value: number,
  index?: number,
  values?: number[] | string[]
) => string | number | null | undefined

export interface BarChartOptionsProps {
  showLabels: boolean
  yAxisCallback?: CallbackType
  xAxisCallback?: CallbackType
}

export const barChartOptions = ({
  yAxisCallback,
  xAxisCallback,
  showLabels,
}: BarChartOptionsProps): ChartOptions => ({
  aspectRatio: 1.5,
  maintainAspectRatio: false,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      top: 15,
    },
  },
  scales: {
    xAxes: [
      {
        display: showLabels,
        ticks: {
          fontFamily: "'Poppins'",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        position: "left",
        ticks: {
          fontFamily: "'Poppins'",
          callback: yAxisCallback,
          padding: 10,
          beginAtZero: true,
        },
        gridLines: {
          drawBorder: false,
        },
      },
    ],
  },
  tooltips: {
    axis: "y",
    callbacks: {
      label: (item, data) => {
        const value = item.value ? Number.parseFloat(item.value) : 0
        const formatted = yAxisCallback ? yAxisCallback(value) : value
        return `${data.datasets?.[item.datasetIndex ?? 0]?.label} 
          ${formatted}`
      },
    },
  },
})

export default barChartOptions
