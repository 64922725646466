/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import clsx from "clsx"
import styles from "./ContextMenu.module.scss"
import Typography from "../Typography/Typography"
import { TextColorType, TextType } from "../../utils/enums"
import { Icon } from "../Icon/Icon"
import { Option } from "../../../framework/types/types"

type ContextMenuProps = {
  className?: string
  options: Option[]
  onClick?: (name: Option) => void
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  className,
  options,
  onClick,
}) => (
  <div className={clsx(styles.root, className)}>
    {options.map((option) => {
      const { value, label, icon } = option
      return (
        <div
          className={styles.item}
          onClick={() => onClick && onClick(option)}
          key={value}
        >
          {icon && <Icon name={icon} className={styles.icon} primary />}
          <Typography type={TextType.h5} color={TextColorType.dark}>
            {label}
          </Typography>
        </div>
      )
    })}
  </div>
)

export default ContextMenu
