import { MetricOption } from "../../../store/account-dashboard/dataTransformers"
import { numberWithCommas, randomNumber } from "../../utils/numberUtils"

export const acquisitionDataMock = [
  { name: "Clicks", amount: 960, trend: 60 },
  { name: "Ctr", amount: "10%", trend: 60 },
  {
    name: "Impressions",
    amount: numberWithCommas(randomNumber(0, 10000)),
    trend: 60,
  },
]

export const trafficQualityDataMock = [
  {
    name: "Res. Click %",
    amount: `10%`,
    trend: 10.06,
  },
  {
    name: "Conversion rate%",
    amount: `1.83%`,
    trend: -0.3,
  },
]

export const transactionsDataMock = [
  {
    name: "AOV",
    amount: `$${numberWithCommas(randomNumber(0, 5000))}`,
    trend: 60,
  },
  {
    name: "Revenue",
    amount: `$${numberWithCommas(randomNumber(0, 5000))}`,
    trend: 60,
  },
  {
    name: "ROAS",
    amount: `$${numberWithCommas(randomNumber(0, 2000))}`,
    trend: -60,
  },
]

export const KPIDropDownListMock: MetricOption[] = [
  { value: "Clicks", label: "Clicks" },
  { value: "Ctr", label: "Ctr" },
  { value: "Impressions", label: "Impressions" },
  { value: "ResClickRate", label: "Res. Click %" },
  { value: "ConversionRate", label: "Conversion %" },
  { value: "AOV", label: "AOV" },
  { value: "Revenue", label: "Revenue" },
  { value: "Roas", label: "ROAS" },
]
