import React, { useMemo } from "react"
import clsx from "clsx"
import { Bar, ChartData } from "react-chartjs-2"
import chartjs from "chart.js"
import optionsCreator from "./config"
import Typography from "../../Typography/Typography"
import { TextColorType, TextType } from "../../../utils/enums"
import { lightColors, NULL } from "../utils"
import { LegendList } from "../Legends/LegendList"
import Box from "../../Box/Box"

import styles from "./BarChart.module.scss"
import { numberWithCommas } from "../../../utils/numberUtils"
import Loader from "../../Loader/Loader"

export interface BarChartProps {
  className?: string
  dataset: DataSetType[]
  labels?: string[]
  title: string
  period?: string
  colors?: string[]
  isLoading?: boolean
  yAxisRender?: (value: number) => string
}

export interface DataSetType {
  label: string
  data: number[]
}

const chartDataProvider = (
  dataset: DataSetType[],
  colors: string[],
  labels?: string[]
): ChartData<chartjs.ChartData> => ({
  labels: labels || [""],
  datasets: dataset.map(({ label, data }, idx) => ({
    label,
    data,
    backgroundColor: colors[idx],
    barPercentage: 1,
    borderAlign: "center",
  })),
})

const BarChart: React.FC<BarChartProps> = ({
  className,
  dataset,
  labels,
  title,
  period,
  colors = lightColors,
  isLoading = false,
  yAxisRender,
}) => {
  const options: any = useMemo(
    () =>
      optionsCreator({
        showLabels: !!labels,
        yAxisCallback: (value: number) =>
          yAxisRender ? yAxisRender(value) : numberWithCommas(value),
      }),
    [yAxisRender, labels]
  )

  const data = useMemo(() => chartDataProvider(dataset, colors, labels), [
    dataset,
    labels,
    colors,
  ])

  return (
    <Box className={clsx(styles.root, className)} height="100%">
      <Typography type={TextType.h2} color={TextColorType.dark} bold>
        {title}
      </Typography>
      {period ? (
        <Typography type={TextType.h6} color={TextColorType.gray} upperCase>
          {period}
        </Typography>
      ) : null}
      <Loader isLoading={isLoading} />
      <div className={styles.chartContainer}>
        <div className={styles.chart}>
          <Bar data={data} options={options} height={NULL} width={NULL} />
        </div>
      </div>
      <div className={styles.legendsContainer}>
        <LegendList
          items={dataset.map(({ label }) => ({ label, dashed: false }))}
          colors={colors}
          key="legends"
        />
      </div>
    </Box>
  )
}

export default BarChart
