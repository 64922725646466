/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from "react"
import clsx from "clsx"
import styles from "./DropDown.module.scss"
import { Icon } from "../Icon/Icon"
import { Option } from "../../../framework/types/types"

export interface IDropDownProps {
  value?: Option
  options: Option[]
  search?: boolean
  onChange?: (value: Option, e: MouseEvent) => void
  className?: string
}

export const DropDown: React.FC<IDropDownProps> = ({
  value,
  options,
  search,
  onChange,
  className,
}) => {
  const rootNode = useRef<HTMLDivElement>(null)

  const [currentValue, setCurrentValue] = useState<Option | undefined>(value)
  const [opened, setOpen] = useState(false)
  const [filter, setFilter] = useState("")

  const handleSearch = (e: any) => {
    setFilter(e.target.value)
  }

  const handleChange = (value: Option, e: MouseEvent | any) => {
    setCurrentValue(value)
    if (onChange) onChange(value, e)
  }

  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  useEffect(() => {
    setCurrentValue(value)
  }, [value])

  useEffect(() => {
    if (!opened) setFilter("")
  }, [opened])

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, options]
  )

  return (
    <div
      className={clsx(styles.root, className, { [styles.active]: opened })}
      ref={rootNode}
      onClick={() => setOpen((value) => !value)}
      role="button"
      tabIndex={0}
    >
      <div className={clsx(styles.wrapper, styles.withLabel)}>
        <input
          className={styles.input}
          value={opened ? filter : currentValue?.label}
          placeholder={currentValue?.label}
          disabled={!search}
          onChange={handleSearch}
        />
        <Icon
          name="arrow-down"
          className={styles.arrow}
          rotateAngle={opened ? 180 : 0}
        />
        {opened ? (
          <ul className={styles.dropdownWrapper}>
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                onClick={handleChange.bind(null, option)}
                className={
                  option.value === currentValue?.value ? styles.selected : ""
                }
              >
                {option.label}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export default DropDown
