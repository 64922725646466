import axios, { AxiosRequestConfig, Method } from "axios"
import config from "../config"
import { AUTH_TOKEN_KEY } from "../framework/constants/auth"

interface RequestProps<Headers, Auth, Data, Params> {
  headers?: Headers
  auth?: Auth
  url: string
  method: Method
  data?: Data
  params?: Params
}

interface Config {
  API_URL: string
  headers?: any
}

class HttpService {
  client = axios.create()

  config: Config = {
    API_URL: config.API_ROOT,
    headers: {
      Accept: "application/json",
    },
  }

  constructor(params: Config) {
    this.config = { ...this.config, ...params }
  }

  getHeaders = <Headers, Auth>(headers: Headers, auth: Auth) => {
    const token = localStorage.getItem(AUTH_TOKEN_KEY)

    const headersObj =
      token && auth ? { ...headers, "X-API-KEY": token } : { ...headers }

    return { ...this.config.headers, ...headersObj }
  }

  getURL = (url: string) => `${this.config.API_URL}/${url}`

  request = <Headers, Auth, Data, Params>(
    props: RequestProps<Headers, Auth, Data, Params>
  ) => {
    const headers = this.getHeaders(props.headers, props.auth)
    const url = this.getURL(props.url)

    const requestParams: AxiosRequestConfig = {
      url,
      headers,
      method: props.method,
      data: props.data,
      params: props.params,
    }

    return axios.request(requestParams)
  }

  post = <Data, Headers>(
    url: string,
    data: Data,
    headers?: Headers,
    auth = true
  ) =>
    this.request({
      url,
      data,
      auth,
      headers,
      method: "post",
    })

  put = <Data>(url: string, data: Data, auth = true) =>
    this.request({
      url,
      data,
      auth,
      method: "put",
    })

  patch = <Data>(url: string, data: Data, auth = true) =>
    this.request({
      url,
      data,
      auth,
      method: "patch",
    })

  get = <Params>(url: string, params?: Params, auth = true) =>
    this.request({
      url,
      params,
      auth,
      method: "get",
    })

  delete = <Data>(url: string, data?: Data, auth = true) =>
    this.request({
      url,
      data,
      auth,
      method: "delete",
    }).catch((error) => error)
}

export default HttpService
