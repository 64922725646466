/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React from "react"
import { observer } from "mobx-react-lite"
import styles from "./SuccessfulCreated.module.scss"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import Button from "../../../../components/ui/Button/Button"
import { TabNames } from "../../CreateCampaign"
import { useStore } from "../../../../store"

type SuccessfulCreatedProps = {
  switchTab: (tabName: TabNames) => void
}

const SuccessfulCreated: React.FC<SuccessfulCreatedProps> = observer(
  ({ switchTab }) => {
    const { accountStore } = useStore()
    const { googleAdwordsAccount } = accountStore
    const handleImplement = () => {
      googleAdwordsAccount
        ? switchTab("Final Success")
        : switchTab("Google Adwords Link")
    }

    return (
      <div className={styles.root}>
        <Typography type={TextType.h2} color={TextColorType.primary} bold>
          Woohoo AdWords Campaigns Successfully Created !
        </Typography>
        <div className={styles.buttonsWrapper}>
          <Button
            variant={ButtonType.contained}
            color={ButtonColor.primary}
            onClick={handleImplement}
          >
            Implement Campaign
          </Button>
          <Button variant={ButtonType.outlined} color={ButtonColor.primary}>
            Download Campaign Package
          </Button>
        </div>
      </div>
    )
  }
)

export default SuccessfulCreated
