/* eslint-disable prettier/prettier */
import { parse } from "date-fns"

export const DATE_MASK = "99/99/9999"
export const fullDateFormatStr = "MM/dd/yyyy"
export const DIVIDER = " - "

export const parseDateStr = (
  dateStr: string,
  multiSelect: boolean
): Date[] | Date =>
  multiSelect
    ? dateStr
      .split(DIVIDER)
      .map((str: string) => parse(str, fullDateFormatStr, new Date()))
    : parse(dateStr, fullDateFormatStr, new Date())

export const dateTest = (d: Date) => !Number.isNaN(d.valueOf())

export const validateDates = (date: Date | Date[]): boolean =>
  Array.isArray(date)
    ? date.map(dateTest).every((v: boolean) => v)
    : dateTest(date)

export default { parseDateStr, validateDates }
