/* eslint-disable no-unused-expressions */
import { observable, action, computed } from "mobx"
import { AccountData } from "../../framework/types/account"
import accountService from "../../services/account.service"
import RootStore from "../RootStore"

export class AccountStore {
  @observable accountId?: number

  @observable googleAdwordsAccount: null = null

  @observable rootStore: RootStore

  @observable accounts: any[] = []

  @observable account: AccountData | null = null

  @observable loading: boolean = false

  @observable sortFilter: { value: string; direction: boolean } = {
    value: "none",
    direction: false,
  }

  constructor(root: RootStore) {
    this.rootStore = root
  }

  @action setAccountId = (accountId: number) => {
    this.accountId = accountId
  }

  @action loadAccountList = async () => {
    this.loading = true
    try {
      const response = await accountService.fetchAccountList()
      this.accounts = response.data.data.records
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  @action loadAccount = async (accountId?: number) => {
    this.loading = true
    try {
      const id = accountId || this.accountId
      if (id) {
        const response = await accountService.getAccount(id)
        this.account = response.data.data
      }
    } catch (error) {
      console.log(error)
    }
    this.loading = false
  }

  @action loadSortedAccountList = async (sortFilter: {
    value: string
    direction: boolean
  }) => {
    this.loading = true
    const dir =
      sortFilter.value === "profile" && sortFilter.direction ? "DESC" : "ASC"
    const response = await accountService.fetchAccountList(dir)
    this.accounts = response.data.data.records
    this.loading = false
  }

  @action sortBy = (sortFilter: { value: string; direction: boolean }) => {
    this.sortFilter = sortFilter
  }

  @computed get accountList() {
    return this.accounts.map((account) => {
      const {
        id,
        name,
        Industry,
        monthlyAdBudget,
        status,
        campaignStatus,
        adAccountId,
      } = account

      return {
        id,
        profileName: name,
        industry: Industry.name,
        totalAdSpend: monthlyAdBudget ? monthlyAdBudget * 12 : 0,
        profileStatus: status,
        profileCompetition: Math.random() > 0.5 ? "In progress" : "Completed",
        linkedAdwordsAccount: adAccountId ? "Yes" : "No",
        campaignStatus,
      }
    })
  }
}

export default AccountStore
