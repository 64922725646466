import React from "react"
import Box from "../../../../components/ui/Box/Box"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  TextColorType,
  TextType,
  ButtonType,
} from "../../../../components/utils/enums"
import styles from "./SelectMonths.module.scss"
import Button from "../../../../components/ui/Button/Button"
import TextField from "../../../../components/ui/TextField/TextField"

export type SelectMonthsProps = {
  selectMonth: (name: string) => void
  spendAmount: number
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  selectedMonths: string[]
  usedMonths: string[]
}

const months = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export type MonthsType = typeof months

const Calculator: React.FC<SelectMonthsProps> = ({
  selectMonth,
  spendAmount,
  onChange,
  selectedMonths,
  usedMonths,
}) => (
  <Box padding="20px 129px 30px 30px" className={styles.root}>
    <Typography type={TextType.h2} color={TextColorType.dark}>
      Select Months to Allocate Spend
    </Typography>
    <Typography type={TextType.h2} color={TextColorType.dark}>
      Additional Spend Amount
    </Typography>

    <div className={styles.monthsContainer}>
      {months.map((name) => (
        <Button
          key={name}
          onClick={() => selectMonth(name)}
          variant={
            selectedMonths.includes(name) || usedMonths.includes(name)
              ? ButtonType.contained
              : ButtonType.outlined
          }
          disabled={usedMonths.includes(name)}
        >
          {name}
        </Button>
      ))}
    </div>

    <div className={styles.form}>
      <TextField value={spendAmount.toString()} onChange={onChange} />
      <Typography type={TextType.h6} color={TextColorType.gray}>
        Monthly
      </Typography>
    </div>
  </Box>
)

export default Calculator
