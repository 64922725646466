import { observable, action } from "mobx"
import agenciesService from "../../services/agencies.service"

export class AgencyStore {
  @observable agencies: any[] = []

  @observable checkedAgency: number | null = null

  @observable loading: boolean = false

  @action loadAgencyList = async () => {
    this.loading = true
    const response = await agenciesService.fetchAgencyList()
    this.agencies = response.data.data
    this.loading = false
  }

  @action setCheckedAgencyId(id: number) {
    this.checkedAgency = id
  }
}

export default AgencyStore
