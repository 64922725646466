import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import interceptor from "../../interceptors/auth.Interceptor"
import { useStore } from "../../store"

export interface SecurityProviderProps {}

const SecurityProvider: React.FC<SecurityProviderProps> = ({ children }) => {
  const rootStore = useStore()
  const { authStore } = rootStore
  const { isTokenChecked, inProgress, checkToken } = authStore

  useEffect(() => interceptor(rootStore), [rootStore])

  useEffect(() => {
    if (!isTokenChecked && !inProgress) {
      checkToken()
    }
  }, [isTokenChecked, inProgress, checkToken])

  return isTokenChecked ? <>{children}</> : <>Loading...</>
}

export default observer(SecurityProvider)
