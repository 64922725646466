import React from "react"
import clsx from "clsx"
import styles from "./TextField.module.scss"
import Typography from "../Typography/Typography"
import { TextType, TextColorType } from "../../utils/enums"
import { Icon } from "../Icon/Icon"

export type TextFieldProps = {
  label?: string
  disabled?: boolean
  placeholder?: string
  error?: string
  helperText?: string
  defaultValue?: string
  className?: string
  value?: string
  type?: string
  withIcon?: boolean
  name?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TextField: React.FC<TextFieldProps> = ({
  label = "",
  error = "",
  defaultValue,
  className = "",
  value,
  onChange,
  placeholder,
  disabled = false,
  type = "text",
  withIcon,
  name,
}) => (
  <div className={clsx(styles.root, className)}>
    <div className={styles.header}>
      {label && (
        <Typography color={TextColorType.gray} type={TextType.h6}>
          {label}
        </Typography>
      )}
      {error && (
        <Typography color={TextColorType.red} type={TextType.h6}>
          {error}
        </Typography>
      )}
    </div>

    <div
      className={clsx(styles.wrapper, {
        [styles.valid]: value && !error,
        [styles.error]: error,
        [styles.withIcon]: withIcon,
      })}
    >
      {withIcon && <Icon name="search" className={styles.icon} />}
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  </div>
)

export default TextField
