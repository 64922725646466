/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react"
import { observer } from "mobx-react-lite"
import RecommendedKeywords from "./components/RecommendedKeywords/RecommendedKeywords"
import AmountSection from "./components/AmountSection/AmountSection"
import { opportunitiesTableMapper } from "./mock.temp"
import Calculator from "./components/Calculator/Calculator"
import { Table } from "./components/Table/Table"
import SelectMonths, {
  MonthsType,
} from "./components/SelectMonths/SelectMonths"
import { useStore } from "../../store"

import styles from "./Opportunities.module.scss"

type OpportunitiesProps = {}

const Opportunities: React.FC<OpportunitiesProps> = observer(() => {
  const { opportunitiesStore, accountStore } = useStore()
  const { account } = accountStore

  const [months, setMonths] = useState<MonthsType>([])
  const [spendAmount, setSpendAmount] = useState(0)
  const [showSelectMonth, setShowSelectMonth] = useState(false)

  const handleSetMonth = (month: string) => {
    setMonths((prev) => {
      if (prev.includes(month)) return prev.filter((item) => item !== month)

      return [...prev, month]
    })
  }

  const handleSetSpendAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSpendAmount(Number.parseInt(value, 10) || 0)
  }

  useEffect(() => {
    opportunitiesStore.setSelectedMonths(months)
  }, [months])

  const accountData = useMemo(() => (account ? [account] : []), [account])

  return (
    <div className={styles.root}>
      <div className={styles.body}>
        <div className={styles.mainTable}>
          <Table data={accountData} dataMapper={opportunitiesTableMapper} />
        </div>
        <div className={styles.selectMonths}>
          {showSelectMonth && (
            <SelectMonths
              selectMonth={handleSetMonth}
              spendAmount={spendAmount}
              onChange={handleSetSpendAmount}
              selectedMonths={months}
              usedMonths={opportunitiesStore.usedMonths}
            />
          )}
        </div>
        <div className={styles.calculator}>
          <Calculator />
        </div>
      </div>
      <div className={styles.keywords}>
        <RecommendedKeywords />
        <AmountSection />
      </div>
    </div>
  )
})

export default Opportunities
