/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import OverallKPIs from "./components/OverallKPIs/OverallKPIs"
import HistoricalKPITrends from "./components/HistoricalKPITrends/HistoricalKPITrends"
import AdSpendBudget from "./components/AdSpendBudget/AdSpendBudget"
import CampaignPerformance from "./components/CampaignPerformance/CampaignPerformance"
import Filter from "./components/Filter/Filter"

import styles from "./Dashboard.module.scss"
import { useStore } from "../../store"
import TechInfo from "./components/TechInfo/TechInfo"

type DashboardProps = {
  className?: string
}

const Dashboard: React.FC<DashboardProps> = observer(({ className }) => {
  const { accountDashboardStore } = useStore()
  const { setDateRange } = accountDashboardStore

  const handleSubmit = (date: Date[]) => {
    setDateRange(date)
  }

  return (
    <div className={clsx(styles.root, className)}>
      <div className={styles.wrapper}>
        <TechInfo />
        <Filter onSubmit={handleSubmit} />
      </div>
      <OverallKPIs />
      <HistoricalKPITrends />
      <AdSpendBudget />
      <CampaignPerformance />
    </div>
  )
})

export default Dashboard
