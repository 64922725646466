/* eslint-disable prettier/prettier */
/* eslint-disable no-underscore-dangle */
import { action, computed, observable } from "mobx"
import moment from "moment"
import { KPIDropDownListMock } from "../../components/ui/KPIsWidget/mockData"
import { MonthlyPerformance, PerformanceMetrics, PerformanceSegmentedReportData } from "../../framework/types/account"
import { InsightOption } from "../../pages/Dashboard/components/Insight/Insight"
import accountService from "../../services/account.service"
import {
  extractMonthlyKPI,
  InsightsMetricsList,
  MetricOption,
  transformInsights,
  transformSegmentedReport,
} from "./dataTransformers"

const YEAR_FORMAT = "YYYY"
const DATE_FORMAT = "YYYY/MM/DD"

class AccountDashboardStore {

  @observable dateRange: Date[] = []

  @observable currentYear = moment(Date.now()).format(YEAR_FORMAT)

  @observable previousYear = moment(Date.now())
    .subtract(1, "year")
    .format(YEAR_FORMAT)

  @observable currYearPerf: MonthlyPerformance[] | null = null

  @observable prevYearPerf: MonthlyPerformance[] | null = null

  @observable performance: PerformanceMetrics | null = null

  @computed get insightList(): InsightOption[] | null {
    if (this.performanceSegmented) {
      return InsightsMetricsList.map((metric) =>
        transformInsights(this.performanceSegmented as PerformanceSegmentedReportData[], metric)
      ).filter((item) => !!item) as InsightOption[]
    }
    return null
  }

  @observable performanceSegmented: PerformanceSegmentedReportData[] | null = null

  @observable error: string | null = null

  @observable metrics: MetricOption[] = [
    KPIDropDownListMock[0],
    KPIDropDownListMock[1],
  ]

  @observable loadingYearPerformance: boolean = false

  @observable loadingPerformance: boolean = false

  @observable loadingSegmentedPerformance: boolean = false

  @computed get currHistoricalKPI() {
    return this.currYearPerf
      ? extractMonthlyKPI(
        this.metrics,
        this.currYearPerf,
        this.currentYear,
        false
      )
      : null
  }

  @computed get prevHistoricalKPI() {
    return this.prevYearPerf
      ? extractMonthlyKPI(
        this.metrics,
        this.prevYearPerf,
        this.previousYear,
        true
      )
      : null
  }

  @action setDateRange = (date: Date[]) => {
    this.dateRange = date
  }

  @action setMetric = (index: number, option: MetricOption) => {
    this.metrics[index] = option
  }

  @action loadYearPerformance = async (accountId: number) => {
    this.loadingYearPerformance = true
    try {
      const [current, previous] = await Promise.all([
        this._loadMonthlyPerformance(accountId, this.currentYear),
        this._loadMonthlyPerformance(accountId, this.previousYear),
      ])
      this.currYearPerf = current
      this.prevYearPerf = previous
    } catch (error) {
      console.log(error)
      this.error = 'error'
    }
    this.loadingYearPerformance = false
  }

  private _loadMonthlyPerformance = async (accountId: number, year: string) =>
    accountService
      .getMonthlyPerformanceReport(accountId, {
        from: `${year}/01/01`,
        to: `${year}/12/31`,
        compare: true
      })
      .then((response) => response.data.data)

  @action loadPerformance = async (accountId: number) => {
    this.loadingPerformance = true

    try {
      const response = await accountService
        .getPerformance(accountId, {
          from: this.currPeriodFrom,
          to: this.currPeriodTo,
          compare: true
        })
        .then((response) => response.data.data)

      this.performance = response
    } catch (error) {
      console.log(error)
      this.error = 'error'
    }
    this.loadingPerformance = false
  }

  @action loadPerformanceSegmentedReport = async (accountId: number) => {
    this.loadingSegmentedPerformance = true

    try {
      this.performanceSegmented = await accountService
        .getPerformanceSegmentedReport(accountId, {
          from: this.currPeriodFrom,
          to: this.currPeriodTo,
          compare: true
        })
        .then((response) => transformSegmentedReport(response.data.data))

    } catch (error) {
      console.log(error)
      this.error = 'error'
    }
    this.loadingSegmentedPerformance = false
  }

  @computed get currPeriodFrom() {
    const dateFrom = this.dateRange[0]
    if (dateFrom) return moment(dateFrom).format(DATE_FORMAT)

    return moment(Date.now()).subtract(1, "month").format(DATE_FORMAT)
  }

  @computed get currPeriodTo() {
    const dateTo = this.dateRange[1]
    if (dateTo) return moment(dateTo).format(DATE_FORMAT)

    return moment(Date.now()).format(DATE_FORMAT)
  }
}

export default AccountDashboardStore