import React from "react"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import { Option } from "../../../../framework/types/types"
import DropDown from "./components/DropDown"
import styles from "./TechInfo.module.scss"

const deviceOptions: Option[] = [
  { value: "Computer", label: "Computer" },
  { value: "Mobile", label: "Mobile devices with full browsers" },
  { value: "Tablets", label: "Tablets with full browsers" },
  { value: "Other", label: "Other" },
]

const networkOptions: Option[] = [
  { value: "SearchNetwork", label: "Search Network" },
]

const TechInfo = () => (
  <div className={styles.root}>
    <div className={styles.block}>
      <Typography
        type={TextType.h4}
        color={TextColorType.dark}
        className={styles.title}
      >
        Device Type
      </Typography>
      <DropDown options={deviceOptions} />
    </div>

    <div className={styles.block}>
      <Typography
        type={TextType.h4}
        color={TextColorType.dark}
        className={styles.title}
      >
        Network Type
      </Typography>
      <DropDown options={networkOptions} />
    </div>
  </div>
)

export default TechInfo
