import { ChartOptions } from "chart.js"

export const options: ChartOptions = {
  maintainAspectRatio: false,
  legend: { display: false },
  layout: {
    padding: {
      left: 5,
      right: 5,
      top: 5,
      bottom: 5,
    },
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 0,
    },
  },
  scales: {
    xAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
      },
    ],
  },
}

export default { options }
