const defaults = {
  API_ROOT: process.env.REACT_APP_API_ROOT,
}

const config = {
  development: {
    ...defaults,
    API_ROOT: "http://35.245.69.62/api",
  },

  production: {
    ...defaults,
    API_ROOT: "http://api.adoro.us",
  },
}

export default config[process.env.REACT_APP_BE_ENV] || config.production
