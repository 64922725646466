import { AxiosResponse } from "axios"
import HttpService from "./http.service"
import config from "../config"
import { UserData } from "../framework/types/auth"

export interface SignInResponse {
  data: UserData
}

export interface GetUserResponse {
  data: UserData
}

class AuthAPI extends HttpService {
  signIn = (
    username: string,
    password: string
  ): Promise<AxiosResponse<SignInResponse>> =>
    this.put("user/auth", {
      username,
      password,
    })

  getUser = (): Promise<AxiosResponse<GetUserResponse>> => this.get("user")
}

export default new AuthAPI({
  API_URL: config.API_ROOT,
})
