import { DataSetType } from "../../../../components/ui/Charts/BarChart/BarChart"
import { darkColors } from "../../../../components/ui/Charts/utils"
import {
  numberWithCommas,
  randomNumber,
} from "../../../../components/utils/numberUtils"
import { Option } from "../../../../framework/types/types"
import { InsightOption } from "../Insight/Insight"

export const BarChatDataMocks = [
  {
    title: "Awareness (CTR%)",
    dataset: [
      { label: "Current", data: [randomNumber(100, 1000)] },
      { label: "Goal", data: [randomNumber(100, 1000)] },
    ],
    colors: darkColors,
    yAxisRender: (value: string) => `${value}%`,
  },
  {
    title: "Consideration (Res Click %)",
    dataset: [
      { label: "Current", data: [randomNumber(100, 1000)] },
      { label: "Goal", data: [randomNumber(100, 1000)] },
    ],
    colors: darkColors,
    yAxisRender: (value: string) => `${value}%`,
  },
  {
    title: "Conversion (ROAS)",
    dataset: [
      { label: "Current", data: [randomNumber(100, 1000)] },
      { label: "Goal", data: [randomNumber(100, 1000)] },
    ],
    colors: darkColors,
    yAxisRender: (value: string) => `$${value}`,
  },
]

export type CampaignKPIKeys = "ctr" | "clicks" | "roas"

export type CampaignKPIDataMapperType = Option & {
  value: CampaignKPIKeys
  yAxisRender: (value: number) => string
}

export type CampaignKPIDataType = {
  [keys in CampaignKPIKeys]: DataSetType[]
}

export const campaignKPIMapper: CampaignKPIDataMapperType[] = [
  {
    label: "Awareness (CTR%)",
    value: "ctr",
    yAxisRender: (value: number) => `${numberWithCommas(value, 2)}%`,
  },
  {
    label: "Consideration (Res Click %)",
    value: "clicks",
    yAxisRender: (value: number) => `${numberWithCommas(value)}%`,
  },
  {
    label: "Conversion (ROAS)",
    value: "roas",
    yAxisRender: (value: number) => `$${numberWithCommas(value)}`,
  },
]

export const insights: InsightOption[] = [
  {
    id: "a",
    value: 30,
    text: "in impressions for Capsul Hotel(Hotel Type)",
    liked: false,
  },
  {
    id: "b",
    value: -20,
    text: "in traffic for Foggy Bottom(Neighborhood)",
    liked: true,
  },
  {
    id: "c",
    value: -75,
    text: "in click through rate for GW University(Attraction)",
    liked: true,
  },
  {
    id: "d",
    value: 8,
    text: "in cost per click for Hip Hotels(Hotel Type)",
    liked: false,
  },
  {
    id: "e",
    value: -5,
    text: "in over all cost for Dar Constitution Hall(Attraction)",
    liked: false,
  },
]
