/* eslint-disable react-hooks/exhaustive-deps */
import React from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import SideBarMenu from "../../../../../components/ui/SideBarMenu/SideBarMenu"
import { defaultActiveOptions } from "../SideBar"
import styles from "./SideMenu.module.scss"

export type SideMenuProps = {
  className?: string
  menuOptions?: { name: string; isLoading: boolean; options: any[] }[]
  onHover?: (id: number, name: string, index: number) => void
  onClick: (id: number, name: string, index: number) => void
  activeOptions: typeof defaultActiveOptions
  isOpen?: boolean
}

export const SideMenu: React.FC<SideMenuProps> = ({
  className,
  menuOptions = [],
  onHover,
  onClick,
  activeOptions,
  isOpen = false,
}) => (
  <div className={clsx(styles.root, className)}>
    {isOpen &&
      menuOptions.map(({ name, isLoading, options }) => (
        <SideBarMenu
          key={name}
          activeOption={activeOptions[name as keyof typeof activeOptions]}
          title={name}
          options={options}
          onHover={onHover}
          onClick={onClick}
          isLoading={isLoading}
        />
      ))}
  </div>
)

export default observer(SideMenu)
