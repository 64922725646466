import React from "react"
import Typography from "../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../components/utils/enums"
import styles from "./PublicLayout.module.scss"

type PublicLayoutProps = {
  children?: React.ReactNode
}

const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => (
  <div className={styles.root}>
    <Typography
      type={TextType.h2}
      color={TextColorType.primary}
      className={styles.title}
    >
      ADORO
    </Typography>
    <div className={styles.wrapper}>{children}</div>
  </div>
)

export default PublicLayout
