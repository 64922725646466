import React, { useEffect } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import styles from "./OverallKPIs.module.scss"
import KPIsWidget from "../../../../components/ui/KPIsWidget/KPIsWidget"
import DataBlock from "../../../../components/ui/KPIsWidget/Components/DataBlock"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import { useStore } from "../../../../store"
import { numberWithCommas } from "../../../../components/utils/numberUtils"
import Loader from "../../../../components/ui/Loader/Loader"
import { PerformanceMetrics } from "../../../../framework/types/account"
import NoData from "../../../../components/ui/NoData/NoData"

type OverallKPIsProps = {
  className?: string
}

export interface Mapper {
  title: string
  subTitle: string
  blockMapper: {
    key: keyof PerformanceMetrics
    label: string
    renderCallback: (value: number) => string
  }[]
}

const mappers: Mapper[] = [
  {
    title: "Acquisition",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      {
        key: "Clicks",
        label: "Clicks",
        renderCallback: numberWithCommas,
      },
      {
        key: "Ctr",
        label: "Ctr",
        renderCallback: (value: number) => `${numberWithCommas(value, 1)}%`,
      },
      {
        key: "Impressions",
        label: "Impressions",
        renderCallback: numberWithCommas,
      },
      {
        key: "Cost",
        label: "Cost",
        renderCallback: (value: number) => `$${numberWithCommas(value)}`,
      },
    ],
  },
  {
    title: "Traffic Quality",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      {
        key: "ResClickRate",
        label: "Res. Click %",
        renderCallback: (value: number) => `${numberWithCommas(value, 1)}%`,
      },
      {
        key: "Conversions",
        label: "Conversions",
        renderCallback: (value: number) => `${numberWithCommas(value)}`,
      },
      {
        key: "AllConversions",
        label: "All Conversions",
        renderCallback: (value: number) => `${numberWithCommas(value)}`,
      },
      {
        key: "ConversionRate",
        label: "Conversion rate%",
        renderCallback: (value: number) => `${numberWithCommas(value, 2)}%`,
      },
    ],
  },
  {
    title: "Transactions",
    subTitle: "(vs. Previous Period)",
    blockMapper: [
      {
        key: "AOV",
        label: "AOV",
        renderCallback: (value: number) => `$${numberWithCommas(value)}`,
      },
      {
        key: "Revenue",
        label: "Revenue",
        renderCallback: (value: number) => `$${numberWithCommas(value)}`,
      },
      {
        key: "Roas",
        label: "ROAS",
        renderCallback: (value: number) => `$${numberWithCommas(value)}`,
      },
    ],
  },
]

const OverallKPIs: React.FC<OverallKPIsProps> = observer(({ className }) => {
  const { accountDashboardStore, accountStore } = useStore()
  const { accountId } = accountStore
  const {
    performance,
    loadPerformance,
    loadingPerformance: loading,
    currPeriodFrom,
  } = accountDashboardStore

  useEffect(() => {
    if (accountId) loadPerformance(accountId)
  }, [accountId, loadPerformance, currPeriodFrom])

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type={TextType.h1} color={TextColorType.primary} bold>
        Overall KPIs
      </Typography>

      <div className={styles.wrapper}>
        {mappers.map(({ title, subTitle, blockMapper }) => (
          <KPIsWidget title={title} subTitle={subTitle}>
            {performance ? (
              blockMapper.map((mapper) => {
                const data = performance[mapper.key]
                const trend = (data.Change ?? 0) * 100
                return (
                  <DataBlock
                    name={mapper.label}
                    amount={mapper.renderCallback(data.Value)}
                    trend={trend}
                    key={mapper.key}
                  />
                )
              })
            ) : loading ? (
              <Loader isLoading={loading} />
            ) : (
              <NoData />
            )}
          </KPIsWidget>
        ))}
      </div>
    </div>
  )
})

export default OverallKPIs
