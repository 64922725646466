import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"
import Login from "./pages/Login/Login"
import RestorePassword from "./pages/RestorePassword/RestorePassword"
import NewPassword from "./pages/NewPassword/NewPassword"
import AccountRoot from "./pages/AccountRoot/AccountRoot"
import PrivateRoute from "./components/security/PrivateRoute"
import AccountList from "./pages/AccountList/AccountList"

const AppRouter: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/" exact>
        <Redirect to="/accounts" />
      </Route>
      <PrivateRoute path="/accounts" component={AccountList} />
      <PrivateRoute path="/account/:id" component={AccountRoot} />
      <Route path="/login" component={Login} />
      <Route path="/restore-password" component={RestorePassword} />
      <Route path="/new-password" component={NewPassword} />
      <Route path="/**">
        <Redirect to="/accounts" />
      </Route>
    </Switch>
  </Router>
)

export default AppRouter
