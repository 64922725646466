import React from "react"
import clsx from "clsx"
import styles from "./Button.module.scss"
import { ButtonColor, ButtonType } from "../../utils/enums"
import { Icon, IconProps } from "../Icon/Icon"

export interface IButtonProps {
  onClick?: (e: any) => void
  variant?: ButtonType
  color?: ButtonColor
  fit?: boolean
  disabled?: boolean
  startIcon?: IconProps
  endIcon?: IconProps
  className?: string
  fluid?: boolean
}

export const Button: React.FC<IButtonProps> = ({
  children,
  disabled = false,
  variant = ButtonType.contained,
  color = ButtonColor.primary,
  fit = false,
  fluid = false,
  startIcon = null,
  endIcon = null,
  onClick,
  className,
}) => {
  const classNames = clsx(
    styles.root,
    className,
    styles[variant],
    styles[color],
    {
      [styles.disabled]: disabled,
      [styles.fit]: fit,
      [styles.fluid]: fluid,
    }
  )
  return (
    <button
      className={classNames}
      onClick={onClick}
      type="button"
      disabled={disabled}
    >
      {startIcon ? <Icon className={styles.startIcon} {...startIcon} /> : null}
      {children}
      {endIcon ? <Icon className={styles.endIcon} {...endIcon} /> : null}
    </button>
  )
}

export default Button
