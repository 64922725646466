import React from "react"
import { StoreProvider } from "./store"
import RootStore from "./store/RootStore"

const store = new RootStore()

const AppProvider: React.FC = ({ children }) => (
  <StoreProvider store={store}>{children}</StoreProvider>
)

export default AppProvider
