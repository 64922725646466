/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react"
import clsx from "clsx"
import styles from "./CheckBox.module.scss"
import { Icon } from "../../Icon/Icon"

type CheckBoxProps = {
  className?: string
  checked?: boolean
  onChange?: () => void
}

const CheckBox: React.FC<CheckBoxProps> = ({
  className,
  checked = false,
  onChange,
}) => (
  <div className={clsx(styles.root, className)} onClick={onChange}>
    {checked && <Icon name="done" />}
  </div>
)

export default CheckBox
