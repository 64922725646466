/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-expressions */
import { action, computed, observable } from "mobx"
import _sortBy from "lodash/sortBy"
import { RecommendedKeyword } from "../../framework/types/opportunities"
import accountService from "../../services/account.service"
import {
  transformEstimate,
  transformKeywords,
} from "./opportunities.transformer"
import Calculator from "./calculator"

class OpportunitiesStore {
  @observable selectedMonths: string[] = []

  @observable searchFiler: string = ""

  @observable usedMonths: string[] = ["Jan", "Apr", "Dec"]

  @observable recommendedKeywords: RecommendedKeyword[] = []

  @observable calculatorStore: Calculator = new Calculator()

  @computed get totalSearchVolumeAvailable(): number {
    return this.recommendedKeywords.reduce(
      (acc, item) => acc + item.avgSearchVolume,
      0
    )
  }

  @computed get selectedKeywords(): RecommendedKeyword[] {
    return this.recommendedKeywords.filter((item) => item.added)
  }

  @observable sortBy: { value: string; direction: boolean } = {
    value: "none",
    direction: false,
  }

  @action loadRecommendedKeywords = async (accountId: number) => {
    try {
      const { status, data: payload } = await accountService.getKeywords(
        accountId
      )
      if (status === 200) {
        this.recommendedKeywords = transformKeywords(payload.data.records)
      }
    } catch (error) {
      //
    }
  }

  @action switchKeyword = async (accountId: number, keywordId: number) => {
    const keyword = this.recommendedKeywords.find(
      (item) => item.id === keywordId
    )
    const activeStatus = !keyword?.added
    if (keyword) keyword.added = activeStatus

    try {
      const { status } = await accountService.switchKeyword(accountId, {
        attributeId: keywordId,
        active: activeStatus,
      })
      if (status !== 200) {
        if (keyword) keyword.added = !activeStatus
      }
    } catch (error) {
      //
    }
  }

  @action setSearchFilter(value: string) {
    this.searchFiler = value
  }

  @action setSelectedMonths = (months: string[]) => {
    this.selectedMonths = months
  }

  @computed get totalMonths() {
    return this.selectedMonths.length
  }

  @computed get getRecommendedKeywords() {
    const { recommendedKeywords, searchFiler, sortBy } = this
    if (searchFiler) {
      const searchWord = searchFiler.toLocaleLowerCase()
      return recommendedKeywords.filter((keyword) => {
        const name = keyword.name.toLocaleLowerCase()
        return name.includes(searchWord)
      })
    }
    const getValue =
      sortBy.value === "estimate"
        ? (data: RecommendedKeyword) => transformEstimate(data, 1)
        : sortBy.value

    return sortBy.direction
      ? _sortBy(recommendedKeywords, [getValue])
      : _sortBy(recommendedKeywords, [getValue]).reverse()
  }

  @action setSortBy = (item: string) => {
    const newValue = {
      value: item,
      direction: !this.sortBy.direction,
    }
    this.sortBy = newValue
  }
}

export default OpportunitiesStore
