import React, { useCallback, useState } from "react"
import clsx from "clsx"
import moment from "moment"
import DatePicker from "../../../../components/ui/DatePicker/DatePicker"
import Button from "../../../../components/ui/Button/Button"
import styles from "./Filter.module.scss"

export type FilterProps = {
  className?: string
  value?: Date[]
  onSubmit: (date: Date[]) => void
}

export const Filter: React.FC<FilterProps> = ({ className, onSubmit }) => {
  const [date, setDate] = useState<Date | Date[]>([
    moment(Date.now()).startOf("month").toDate(),
    moment(Date.now()).toDate(),
  ])

  const handleSubmit = useCallback(() => {
    if (Array.isArray(date)) onSubmit(date)
  }, [onSubmit, date])

  return (
    <div className={clsx(styles.root, className)}>
      <DatePicker defValue={date} onChange={setDate} multiSelect />
      <Button
        onClick={handleSubmit}
        disabled={Array.isArray(date) && date.length === 0}
      >
        Apply
      </Button>
    </div>
  )
}

export default Filter
