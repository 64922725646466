/* eslint-disable react/no-array-index-key */
import React, { useMemo } from "react"
import { observer } from "mobx-react-lite"

import BarChart from "../../../../components/ui/Charts/BarChart/BarChart"
import {
  CampaignKPIDataType,
  campaignKPIMapper,
} from "../CampaignPerformance/MockData"
import { darkColors } from "../../../../components/ui/Charts/utils"
import { useStore } from "../../../../store"
import { transformCampaignKPIData } from "../../../../store/account-dashboard/dataTransformers"
import Loader from "../../../../components/ui/Loader/Loader"
import NoData from "../../../../components/ui/NoData/NoData"

import styles from "./CampaignKPI.module.scss"

type CampaignSegmentationProps = {}

const CampaignKPI: React.FC<CampaignSegmentationProps> = observer(() => {
  const { accountStore, accountDashboardStore } = useStore()
  const { account } = accountStore
  const {
    performanceSegmented,
    loadingSegmentedPerformance: loading,
  } = accountDashboardStore
  const data = useMemo<CampaignKPIDataType | null>(() => {
    if (account && performanceSegmented && performanceSegmented.length === 3) {
      return transformCampaignKPIData(account, performanceSegmented)
    }
    return null
  }, [account, performanceSegmented])

  return (
    <div className={styles.root}>
      {data ? (
        campaignKPIMapper.map(({ label, value, yAxisRender }) => (
          <div className={styles.column} key={label}>
            <BarChart
              dataset={data[value]}
              title={label}
              yAxisRender={yAxisRender}
              colors={darkColors}
            />
          </div>
        ))
      ) : loading ? (
        <Loader />
      ) : (
        <NoData />
      )}
    </div>
  )
})

export default CampaignKPI
