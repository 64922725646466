/* eslint-disable @typescript-eslint/no-unused-vars */
import { RecommendedKeyword } from "../../framework/types/opportunities"

export interface KeywordEntity {
  activeEndDate: Date
  activeStartDate: Date
  assignedProperties: {
    active: boolean
    hasKeywords: boolean
  }[]
  attributeStats: [
    {
      attributeId: number | null
      volume: number
      id: number
      name: string
      attribute: null
      competition: number
      cpc: number
    }
  ]
  monthlyVolume: { date: string; volume: number }[]
  category: { id: number; name: string }
  categoryId: number
  distance: number
  id: number
  name: string
  point: [number, number]
  source: string
  subCategory: string
  thirdPartyId: string
}

export const transformKeywords = (
  keyword: KeywordEntity[]
): RecommendedKeyword[] => keyword.map(transformKeyword)

export const transformKeyword = (
  keyword: KeywordEntity
): RecommendedKeyword => {
  const {
    id,
    name,
    attributeStats,
    distance,
    assignedProperties,
    monthlyVolume,
  } = keyword

  const stat = attributeStats?.length ? attributeStats[0] : null
  const avgCpc = stat?.cpc ?? 0
  const mySearchVolume = stat?.volume ?? 0
  const avgSearchVolume = stat?.volume ?? 0
  const estimate = avgCpc * avgSearchVolume
  const added = assignedProperties[0].active
  const graphPoints = monthlyVolume?.map((item) => item.volume) || []
  return {
    id,
    added,
    name,
    avgSearchVolume,
    estimate,
    distance,
    avgCpc,
    mySearchVolume,
    relevanceScope: 0,
    graphPoints,
  }
}

export const transformEstimate = (data: RecommendedKeyword, ctr: number = 0) =>
  data.estimate * (ctr / 100)

export const transformCalculatorValues = (
  data: KeywordEntity[]
): { avgCpc: number; mySearchVolume: number } => {
  const accumulator = { cpc: 0, volume: 0 }
  const sum = data.reduce(reducer, accumulator)
  const avgCpc = sum.cpc / data.length
  const mySearchVolume = sum.volume

  return { avgCpc, mySearchVolume }
}

export const reducer = (
  accumulator: { cpc: number; volume: number },
  keyword: KeywordEntity
): { cpc: number; volume: number } => {
  const { attributeStats } = keyword

  const stat = attributeStats ? attributeStats[0] : null
  const volume = stat?.volume ?? 0
  const cpc = stat?.cpc ?? 1 * 0

  return { cpc: accumulator.cpc + cpc, volume: accumulator.volume + volume }
}
