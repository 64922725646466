import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
import Button from "../../../../components/ui/Button/Button"
import ContextMenu from "../../../../components/ui/ContextMenu/ContextMenu"
import { ButtonColor, ButtonType } from "../../../../components/utils/enums"
import { Option } from "../../../../framework/types/types"
import { useStore } from "../../../../store"
import styles from "./ProfileMenu.module.scss"

const optionsSmall: Option[] = [
  { value: "settings", label: "Settings" },
  { value: "logout", label: "Log Out" },
]

export interface ProfileMenuProps {}

export const ProfileMenu: React.FC<ProfileMenuProps> = () => {
  const { authStore } = useStore()

  const name = authStore.fullName

  const rootNode = useRef<HTMLDivElement>(null)
  const [opened, setOpen] = useState(false)
  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handlePick = (option: Option) => {
    if (option.value === "logout") authStore.logout()
  }

  return (
    <div ref={rootNode} className={styles.root}>
      <Button
        variant={ButtonType.ghost}
        color={ButtonColor.primary}
        onClick={() => setOpen((value) => !value)}
        startIcon={{ name: "user", primary: true }}
        endIcon={{
          name: "arrow-down",
          className: styles.arrowIcon,
          rotateAngle: opened ? 180 : 0,
        }}
        fit
      >
        {name}
      </Button>
      {opened && (
        <div className={styles.menuContainer}>
          <ContextMenu options={optionsSmall} onClick={handlePick} />
        </div>
      )}
    </div>
  )
}

export default observer(ProfileMenu)
