import {
  numberWithCommas,
  randomNumber,
} from "../../../../components/utils/numberUtils"
import { TableColumnProps, TableMapperItem } from "./Table"

// export const clientTableMapper: TableMapperItem[] = [
//   { name: "account", label: "Account" },
//   { name: "impressions", label: "Impressions" },
//   { name: "imprShare", label: "Impr. Share" },
//   { name: "clicks", label: "Clicks" },
//   { name: "ctr", label: "CTR%" },
//   { name: "avgCpc", label: "Avg. CPC" },
//   { name: "cost", label: "Cost" },
//   { name: "conversions", label: "Conversions" },
//   { name: "convRate", label: "Conv. Rate %" },
//   { name: "revenue", label: "Revenue" },
//   { name: "roas", label: "ROAS" },
//   { name: "plannedBudget", label: "Planned Budget" },
//   { name: "actual", label: "Actual" },
// ]

export const accountTableMapperPart: TableMapperItem[] = [
  {
    renderLabel: (value: number) => numberWithCommas(value),
    name: "Impressions",
    label: "Impressions",
  },
  {
    renderLabel: (value: number) => `${numberWithCommas(value, 1)}%`,
    name: "SearchImpressionShare",
    label: "Impr. Share",
  },
  {
    renderLabel: (value: number) => numberWithCommas(value),
    name: "Clicks",
    label: "Clicks",
  },
  {
    renderLabel: (value: number) => `${numberWithCommas(value, 1)}%`,
    name: "Ctr",
    label: "CTR%",
  },
  {
    renderLabel: (value: number) => `$${numberWithCommas(value, 2)}`,
    name: "AverageCpc",
    label: "Avg. CPC",
  },
  {
    renderLabel: (value: number) => `$${numberWithCommas(value)}`,
    name: "Cost",
    label: "Cost",
  },
  {
    renderLabel: (value: number) => numberWithCommas(value),
    name: "Conversions",
    label: "Conversions",
  },
  {
    renderLabel: (value: number) => numberWithCommas(value),
    name: "AllConversions",
    label: "All Conversions",
  },
  {
    renderLabel: (value: number) => `${numberWithCommas(value, 2)}%`,
    name: "ConversionRate",
    label: "Conv. Rate %",
  },
  {
    renderLabel: (value: number) => `$${numberWithCommas(value)}`,
    name: "Revenue",
    label: "Revenue",
  },
  {
    renderLabel: (value: number) => `$${numberWithCommas(value, 2)}`,
    name: "Roas",
    label: "ROAS",
  },
]

export const accountTableMapper: TableMapperItem[] = [...accountTableMapperPart]

export const randomNumbersArray = (mapper: TableMapperItem[]) =>
  Object.fromEntries(
    mapper.map<[string, TableColumnProps]>(({ name }) => [
      name,
      {
        label: randomNumber(0, 10000),
        subLabel: randomNumber(-100, 100),
      },
    ])
  )

// export const segmentationDetailsMock: TableDatasetType[] = [
//   {
//     name: { label: "Brand" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
//   {
//     name: { label: "Upen Hospital" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
//   {
//     name: { label: "Airport Hotel" },
//     ...randomNumbersArray(accountTableMapperPart),
//   },
// ]
