/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable default-case */
import React, { useEffect, useRef, useState, useCallback } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import { useHistory } from "react-router-dom"
import _ from "lodash"
import { Option } from "../../../../framework/types/types"
import { Icon } from "../../../../components/ui/Icon/Icon"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import SideMenu from "./components/SideMenu"
import { useStore } from "../../../../store"
import styles from "./SideBar.module.scss"

export interface SideBarProps {
  options: Option[]
}

export const defaultActiveOptions = {
  Agencies: -1,
  Clients: -1,
  Accounts: -1,
}

const SideBar: React.FC<SideBarProps> = observer(({ options }) => {
  const [activeOptions, setActiveOptions] = useState(defaultActiveOptions)
  const [activeTab, setActiveTab] = useState("")
  const {
    sideBarMenuStore,
    agencyStore,
    clientStore,
    accountStore,
  } = useStore()
  const history = useHistory()

  const isOpen = activeTab === "Agency"

  const throttledLoadClients = useCallback(
    _.throttle((id) => clientStore.loadClientList(id), 600),
    []
  )

  const throttledLoadAccounts = useCallback(
    _.throttle(() => accountStore.loadAccountList(), 600),
    []
  )

  const handleHideMenu = () => {
    setActiveTab("")
  }

  const handlePick = (id: number, name: string, index: number) => {
    if (name === "Agencies") {
      //
    }

    if (name === "Clients") {
      //
    }

    if (name === "Accounts") {
      history.push(`/account/${id}/dashboard`)
    }
  }

  const handleHoverOption = (id: number, name: string, index: number) => {
    sideBarMenuStore.setCheckedMenu(name)

    if (name === "Agencies") {
      setActiveOptions({ ...defaultActiveOptions, Agencies: index })
      agencyStore.checkedAgency !== id && throttledLoadClients(id)
    }

    if (name === "Clients") {
      setActiveOptions((prev) => ({ ...prev, Clients: index, Accounts: -1 }))
      clientStore.checkedClient !== id && throttledLoadAccounts()
      clientStore.setCheckedClient(id)
    }

    if (name === "Accounts") {
      setActiveOptions((prev) => ({ ...prev, Accounts: index }))
    }
  }

  const rootNode = useRef<HTMLDivElement>(null)

  const handleHide = useCallback((e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    handleHideMenu()
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", handleHide)

    return () => {
      document.removeEventListener("mousedown", handleHide)
    }
  }, [handleHide])

  const handleClick = (name: string) => {
    switch (name) {
      case "Agency": {
        setActiveTab((value) => (value && value === "Agency" ? "" : name))
        agencyStore.loadAgencyList()
        break
      }
      default: {
        setActiveTab(name)
        break
      }
    }
  }

  return (
    <div className={styles.root} ref={rootNode}>
      <Typography
        color={TextColorType.light}
        type={TextType.h1}
        className={styles.header}
      >
        ADORO
      </Typography>
      <ul className={styles.list}>
        {options.map(({ value, label, icon }) => (
          <li
            className={clsx(styles.item, {
              [styles.active]: activeTab === label,
            })}
            key={value}
            onClick={() => handleClick(label)}
          >
            {icon && <Icon name={icon} className={styles.icon} />}
            {label.toUpperCase()}
          </li>
        ))}
      </ul>
      <SideMenu
        isOpen={isOpen}
        menuOptions={sideBarMenuStore.menuOptions}
        activeOptions={activeOptions}
        onHover={handleHoverOption}
        onClick={handlePick}
      />
    </div>
  )
})

export default SideBar
