/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Calendar from "react-calendar"
import clsx from "clsx"
import { format } from "date-fns"
import ReactInputMask from "react-input-mask"
import styles from "./DatePicker.module.scss"
import "./Calendar.scss"
import {
  DATE_MASK,
  DIVIDER,
  fullDateFormatStr,
  parseDateStr,
  validateDates,
} from "./utils"
import { Icon } from "../Icon/Icon"

interface DatePickerProps {
  multiSelect?: boolean
  onChange?: (date: Date | Date[]) => void
  defValue?: Date | Date[]
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  multiSelect = false,
  defValue,
}) => {
  const [isValid, setValid] = useState(true)
  const [isOpen, setOpen] = useState(false)
  const rootNode = useRef<HTMLDivElement>(null)
  const [date, setDate] = useState<Date | Date[] | undefined>()
  const [value, setValue] = useState<string>("")

  const handleClick = useCallback((e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setOpen(false)
  }, [])

  useEffect(() => {
    if (defValue) handlePick(defValue)
  }, [])

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [handleClick])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setValue(value)

      const dates = parseDateStr(value || " ", multiSelect)
      if (validateDates(dates)) {
        setDate(dates)
        onChange && onChange(dates)
      } else onChange && onChange([])
    },
    [multiSelect, onChange]
  )

  const mask = useMemo(
    () => (multiSelect ? `${DATE_MASK}${DIVIDER}${DATE_MASK}` : `${DATE_MASK}`),
    [multiSelect]
  )

  const handleBlur = useCallback(() => {
    if (value !== "__/__/____ - __/__/____") {
      const isDateValid = validateDates(parseDateStr(value, multiSelect))
      setValid(isDateValid)
    }
  }, [value, multiSelect])

  const handlePick = useCallback(
    (value: Date | Date[]) => {
      const dateStr =
        value && !Array.isArray(value)
          ? format(value, fullDateFormatStr)
          : value?.map((curr) => format(curr, fullDateFormatStr)).join(DIVIDER)
      setValue(dateStr)

      setDate(value)
      if (onChange) onChange(value)

      setValid(true)
    },
    [onChange]
  )

  return (
    <div
      className={clsx(styles.root, { [styles.active]: isOpen })}
      ref={rootNode}
      role="button"
      tabIndex={0}
    >
      <div className={styles.wrapper}>
        <ReactInputMask
          type="text"
          mask={mask}
          value={value}
          className={clsx(styles.input, {
            [styles.invalid]: !isValid,
          })}
          onChange={handleChange}
          onClick={() => setOpen(false)}
          onFocus={() => setValid(true)}
          onBlur={handleBlur}
        />
        <Icon
          name="calendar"
          className={styles.arrow}
          onClick={() => setOpen((value) => !value)}
        />
        {isOpen ? (
          <Calendar
            selectRange={multiSelect}
            onChange={handlePick}
            value={date}
            className="react-calendar"
            formatMonthYear={(_, date) => format(date, "MMMM yyyy")}
            formatMonth={(_, date) => format(date, "MMMM")}
            formatShortWeekday={(_, date) => format(date, "EEEEE")}
          />
        ) : null}
      </div>
    </div>
  )
}

export default DatePicker
