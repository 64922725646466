/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import styles from "./BuildingAccount.module.scss"
import { useStore } from "../../../../store"
import Typography from "../../../../components/ui/Typography/Typography"
import {
  QuadSizeType,
  TextColorType,
  TextType,
} from "../../../../components/utils/enums"
import IndicatedProgressBar from "../../../../components/ui/ProgressBar/IndicatedProgressBar/IndicatedProgressBar"
import Box from "../../../../components/ui/Box/Box"
import { TabNames } from "../../CreateCampaign"

type BuildingAccountProps = {
  switchTab: (switchTab: TabNames) => void
}

const BuildingAccount: React.FC<BuildingAccountProps> = observer(
  ({ switchTab }) => {
    const [progress, setProgress] = useState(0)
    const [intervalId, setIntervalId] = useState<any>()

    const increaseProgress = () => {
      setProgress((current) => current + 1)
    }

    useEffect(() => {
      setIntervalId(setInterval(increaseProgress, 50))
    }, [])

    useEffect(() => {
      if (progress === 100) {
        clearInterval(intervalId)
        switchTab("Successful Created")
      }
    }, [progress, intervalId, switchTab])

    return (
      <div className={styles.root}>
        <Typography type={TextType.h2} color={TextColorType.primary} bold>
          Building Ad Words Account
        </Typography>
        <Box
          padding="16px 30px 30px 30px"
          width="100%"
          className={styles.progressWrapper}
        >
          <IndicatedProgressBar
            value={progress}
            size={QuadSizeType.small}
            className={styles.progress}
          />
        </Box>
        <Typography type={TextType.h4} color={TextColorType.dark}>
          Building Adwords Account Structure
        </Typography>
      </div>
    )
  }
)

export default BuildingAccount
