import React from "react"
import Typography from "../../Typography/Typography"
import { TextColorType, TextType } from "../../../utils/enums"
import { Icon } from "../../Icon/Icon"

import styles from "../KPIsWidget.module.scss"

export type DataBlockProps = {
  name: string
  amount: number | string
  trend: number
}

const DataBlock: React.FC<DataBlockProps> = ({ name, amount, trend }) => {
  const trendDirection = trend > 0
  return (
    <div className={styles.item} key={name}>
      <Typography type={TextType.h6} color={TextColorType.gray} upperCase>
        {name}
      </Typography>
      <Typography type={TextType.h2} color={TextColorType.dark} bold>
        {amount}
      </Typography>
      <Typography
        type={TextType.h5}
        color={!trendDirection ? TextColorType.red : TextColorType.lightGreen}
        className={styles.percent}
      >
        <Icon
          name="arrow-down"
          className={trendDirection ? styles.upArrow : styles.downArrow}
          rotateAngle={trendDirection ? 180 : 0}
        />
        {`${trend.toFixed(0)}%`}
      </Typography>
    </div>
  )
}

export default DataBlock
