/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import NumberFormat, { NumberFormatValues } from "react-number-format"
import clsx from "clsx"
import Box from "../../../../components/ui/Box/Box"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import { calculatorTableMapper } from "../../mock.temp"
import { DatasetType, Table } from "../Table/Table"
import TextField from "../../../../components/ui/TextField/TextField"
import { useStore } from "../../../../store"
import { CalculatorDataIn } from "../../../../framework/types/opportunities"
import { Option } from "../../../../framework/types/types"
import Sticky from "../../../../components/ui/Sticky/Sticky"

import styles from "./Calculator.module.scss"

export type CalculatorProps = {}

export interface FieldMapper extends Option {
  value: keyof CalculatorDataIn
}

const calculatorStickyStyle = {
  top: "75px",
  zIndex: 1,
}

const mapper: FieldMapper[] = [
  { value: "monthlyAdBudget", label: "Monthly ad budget $" },
  { value: "avgCpc", label: "Avg.cpc $ " },
  { value: "ctr", label: "Ctr %" },
  { value: "conversion", label: "Website conversion %" },
  { value: "aov", label: "Aov $" },
]

const Calculator: React.FC<CalculatorProps> = observer(() => {
  const { opportunitiesStore } = useStore()
  const { calculatorStore } = opportunitiesStore
  calculatorStore.selectedKeywords = opportunitiesStore.selectedKeywords
  const { calculatorOnChange } = calculatorStore

  const getSuffix = (name: any) =>
    name === "ctr" || name === "conversion" ? "%" : ""

  const getPrefix = (name: any) =>
    name !== "ctr" && name !== "conversion" ? "$" : ""

  useEffect(() => {
    calculatorStore.avgCpc = calculatorStore.calculatedAvgCpc
  }, [calculatorStore.calculatedAvgCpc])

  return (
    <span className="calculate-parent">
      <Box padding="0px" className={clsx(styles.root)}>
        <div className={styles.header}>
          <Typography type={TextType.h1} color={TextColorType.dark}>
            Selected Keyword Calculator
          </Typography>
        </div>
        <div className={styles.form}>
          {mapper.map(({ value, label }) => (
            <div key={value}>
              <Typography color={TextColorType.gray} type={TextType.h6}>
                {label.toUpperCase()}
              </Typography>
              <NumberFormat
                key={value}
                decimalScale={2}
                customInput={TextField}
                thousandSeparator
                prefix={getPrefix(value)}
                suffix={getSuffix(value)}
                value={calculatorStore[value] || ""}
                onValueChange={(values: NumberFormatValues) =>
                  calculatorOnChange(values, value)
                }
              />
            </div>
          ))}
        </div>
        <div className={styles.body}>
          <div className={styles.divider} />
          <Sticky topOffset={-75} stickyStyle={calculatorStickyStyle}>
            <div className={styles.tableContainer}>
              <Table
                data={[(calculatorStore as unknown) as DatasetType]}
                dataMapper={calculatorTableMapper}
              />
            </div>
          </Sticky>
        </div>
      </Box>
    </span>
  )
})

export default Calculator
