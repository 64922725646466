import {
  randomNumber,
  randomNumbersArray,
} from "../../../../components/utils/numberUtils"
import { LineDataType } from "../../../../components/ui/Charts/LineChart/LineChart"

const maxValue = 1000

const KPI1 = randomNumbersArray(12, maxValue)
const KPI2 = randomNumbersArray(12, maxValue)

export const KPITrends2021DataMock: LineDataType = {
  dataset: [
    {
      yAxisID: "A",
      key: "KPI 1",
      label: "KPI 1 2021",
      data: KPI1.map((value) => randomNumber(value, 1000)),
    },
    {
      yAxisID: "B",
      key: "KPI 2",
      label: "KPI 2 2021",
      data: KPI2.map((value) => randomNumber(value, 1000)),
    },
  ],
}

export const KPITrends2020DataMock: LineDataType = {
  dashed: true,
  dataset: [
    {
      yAxisID: "A",
      key: "KPI 1",
      label: "KPI 1 2020",
      data: KPI1,
    },
    {
      yAxisID: "B",
      key: "KPI 2",
      label: "KPI 2 2020",
      data: KPI2,
    },
  ],
}

export const labels = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
]
