import React from "react"
import { observer } from "mobx-react-lite"
import Typography from "../../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../../components/utils/enums"
import { Row } from "./Row"
import { Icon } from "../../../../../components/ui/Icon/Icon"
import { Option } from "../../../../../framework/types/types"
import { RecommendedKeyword } from "../../../../../framework/types/opportunities"

import styles from "./Table.module.scss"

export type TableMapper = {
  sortable?: boolean
} & Option

export interface TableHeaderProps {
  dataMapper: TableMapper[]
  onSort?: (value: string) => void
  sortBy: { value: string; direction: boolean }
}

export const TableHeader: React.FC<TableHeaderProps> = observer(
  ({ dataMapper, onSort, sortBy }) => (
    <div className={styles.header}>
      {dataMapper.map(({ label, sortable, value }) => (
        <Typography
          onClick={() => sortable && onSort?.(value)}
          className={styles.column}
          type={TextType.h6}
          color={TextColorType.gray}
          key={value}
        >
          {label.toUpperCase()}
          {sortable && (
            <span className={styles.sortArrow}>
              <Icon
                name={sortBy.value === value ? "arrow-down" : "none"}
                rotateAngle={sortBy.direction ? 180 : 0}
              />
            </span>
          )}
        </Typography>
      ))}
    </div>
  )
)

export interface TableBodyProps {
  accountId?: number
  data: RecommendedKeyword[]
  ctr: number
  onClick: (accountId: number, keywordId: number) => void
}

export const TableBody: React.FC<TableBodyProps> = observer(
  ({ accountId, data, ctr, onClick }) => (
    <div className={styles.list}>
      {data.map((item) => (
        <Row
          {...item}
          ctr={ctr}
          key={item.id}
          onClick={onClick}
          accountId={accountId}
        />
      ))}
    </div>
  )
)
