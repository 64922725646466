import React from "react"
import { observer } from "mobx-react-lite"
import clsx from "clsx"
import Button from "../Button/Button"
import Typography from "../Typography/Typography"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../utils/enums"
import styles from "./SideBarMenu.module.scss"
import Loader from "../Loader/Loader"

export type SideBarMenuProps = {
  className?: string
  title: string
  options?: any[]
  isLoading?: boolean
  onClick: (id: number, name: string, index: number) => void
  onHover?: (id: number, name: string, index: number) => void
  activeOption: number | null
}

export const SideBarMenu: React.FC<SideBarMenuProps> = ({
  className,
  title,
  options = [],
  onClick,
  onHover,
  activeOption,
  isLoading = false,
}) => {
  const renderOptions = () => (
    <>
      {!options?.length ? (
        <span className={styles.buttonWrapper}>Not found</span>
      ) : (
        options.map((option, index) => {
          const { name, id } = option

          return (
            <div
              key={id}
              className={clsx(styles.buttonWrapper, {
                [styles.active]: activeOption === index,
              })}
              onMouseEnter={() => onHover && onHover(id, title, index)}
            >
              <Button
                variant={ButtonType.ghost}
                color={ButtonColor.primary}
                onClick={() => onClick(id, title, index)}
              >
                {name || "(No name)"}
              </Button>
            </div>
          )
        })
      )}
    </>
  )

  return (
    <div className={clsx(styles.root, className)}>
      <Typography type={TextType.h4} color={TextColorType.mediumDarkBlue} bold>
        {title}
      </Typography>
      <Loader isLoading={isLoading} animationSpeed="2s" />
      {renderOptions()}
    </div>
  )
}

export default observer(SideBarMenu)
