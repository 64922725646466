/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-undef */
import clsx from "clsx"
import React, { useEffect, useRef, useState } from "react"
import { Icon } from "../../../../../components/ui/Icon/Icon"
import { Option } from "../../../../../framework/types/types"
import styles from "./DropDown.module.scss"

export interface IDropDownProps {
  options: Option[]
}

const DropDown: React.FC<IDropDownProps> = ({ options }) => {
  const rootNode = useRef<HTMLDivElement>(null)
  const [opened, setOpen] = useState(false)
  const placeholder = options.map((item) => item.label).join(", ")

  const handleClick = (e: MouseEvent) => {
    if (rootNode.current && rootNode.current.contains(e.target as Node)) {
      return
    }
    setOpen(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  return (
    <div
      className={clsx(styles.root, { [styles.active]: opened })}
      ref={rootNode}
      onClick={() => setOpen((value) => !value)}
      role="button"
      tabIndex={0}
    >
      <div className={clsx(styles.wrapper, styles.withLabel)}>
        <input className={styles.input} placeholder={placeholder} disabled />
        <Icon
          name="arrow-down"
          className={styles.arrow}
          rotateAngle={opened ? 180 : 0}
        />
        {opened ? (
          <ul className={styles.dropdownWrapper}>
            {options.map((option) => (
              <li key={option.value}>
                {option.label}
                <Icon name="done" className={styles.checkmark} />
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </div>
  )
}

export default DropDown
