/* eslint-disable no-console */
import React from "react"
import clsx from "clsx"
import Button from "../Button/Button"
import { ButtonColor, ButtonType } from "../../utils/enums"
import { Option } from "../../../framework/types/types"
import styles from "./NavBar.module.scss"

export interface NavOption extends Option {
  disabled?: boolean
}

export type NavBarProps = {
  className?: string
  options: NavOption[]
  currentValue?: NavOption
  onChange?: (value: NavOption) => void
}

export const NavBar: React.FC<NavBarProps> = ({
  className,
  options,
  currentValue,
  onChange,
}) => (
  <div className={clsx(styles.root, className)}>
    {options.map((item) => {
      const { label, icon, value } = item
      return (
        <Button
          className={clsx(styles.button, {
            [styles.active]: currentValue?.value === value,
          })}
          variant={ButtonType.ghost}
          color={ButtonColor.primary}
          startIcon={{ name: icon ?? "file", primary: true }}
          onClick={() => onChange && onChange(item)}
          key={value}
        >
          {label}
        </Button>
      )
    })}
  </div>
)

export default NavBar
