import { observer } from "mobx-react-lite"
import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useStore } from "../../store"

export interface PrivateRouteProps extends RouteProps {}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...props }) => {
  const { authStore } = useStore()
  console.log(authStore)
  return authStore.isAuthorized ? (
    <Route {...props} />
  ) : (
    <Redirect to="/login" />
  )
}

export default observer(PrivateRoute)
