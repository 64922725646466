/* eslint-disable no-debugger */
import React from "react"
import clsx from "clsx"
import { Option } from "../../framework/types/types"
import TextField from "../../components/ui/TextField/TextField"
import SideBar from "./components/SideBar/SideBar"
import styles from "./PrivateLayout.module.scss"
import Button from "../../components/ui/Button/Button"
import {
  ButtonColor,
  ButtonType,
  TextColorType,
  TextType,
} from "../../components/utils/enums"
import Typography from "../../components/ui/Typography/Typography"
import { NavOption, NavBar } from "../../components/ui/NavBar/NavBar"
import Notifications from "./components/Notifications/Notifications"
import ProfileMenu from "./components/ProfileMenu/ProfileMenu"
import { Sticky } from "../../components/ui/Sticky/Sticky"

const navItems: Option[] = [
  { value: "agency", label: "Agency", icon: "building" },
  { value: "report", label: "Report", icon: "statistics" },
  { value: "settings", label: "Settings", icon: "settings" },
  { value: "users", label: "Users", icon: "user" },
]

export interface NavBarProps {
  title: string
  options: NavOption[]
  currentTab?: NavOption
  onSelect?: (selected: NavOption) => void
}

export interface PrivateLayoutProps {
  onBack?: () => void
  onSearch?: (value: string) => void
  navBar?: NavBarProps
}

const rootStyles = {
  display: "flex",
  position: "relative" as any,
  width: "100%",
  minHeight: "100vh",
  flexFlow: "column",
  flexGrow: 1,
  backgroundColor: "#fff",
  alignContent: "stretch",
  background: "#f6f8ff",
  minWidth: "1440px",
  zIndex: 3,
}

export const STICKY_ROOT = "sticky-root"

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({
  children,
  navBar,
  onBack,
}) => (
  <div style={rootStyles}>
    <div className={styles.header}>
      <Sticky stickyStyle={{ zIndex: 5 }} topOffset={0}>
        <div className={styles.headerRow} style={{ zIndex: 1000 }}>
          <div className={styles.headerContent}>
            <div className={styles.search}>
              {onBack && (
                <Button
                  startIcon={{ name: "long-arrow-right", rotateAngle: 180 }}
                  variant={ButtonType.ghost}
                  color={ButtonColor.secondary}
                  fit
                  className={styles.backButton}
                  onClick={onBack}
                >
                  Back
                </Button>
              )}
              <TextField
                withIcon
                placeholder="Search"
                className={styles.input}
              />
            </div>
            <div className={styles.profile}>
              <Notifications />
              <ProfileMenu />
            </div>
          </div>
        </div>
      </Sticky>
      {navBar ? (
        <div className={styles.headerRow}>
          <div className={styles.navContainer}>
            <Typography type={TextType.h2} color={TextColorType.primary}>
              {navBar.title}
            </Typography>

            <NavBar
              options={navBar.options}
              currentValue={navBar.currentTab}
              onChange={navBar.onSelect}
            />
          </div>
        </div>
      ) : null}
      <div className={styles.sidebar}>
        <SideBar options={navItems} />
      </div>
    </div>
    <div className={styles.body}>
      <div className={clsx(styles.bodyContainer)}>{children}</div>
    </div>
  </div>
)
export default PrivateLayout
