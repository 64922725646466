/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useState } from "react"

import clsx from "clsx"
import {
  TrendValue,
  TrendValueProps,
} from "../../../../components/ui/DataChangeWidget/DataChangeWidget"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import Box from "../../../../components/ui/Box/Box"
import { Icon } from "../../../../components/ui/Icon/Icon"
import {
  MetricNameType,
  PerformanceMetrics,
} from "../../../../framework/types/account"

import styles from "./CampaignSegmentationTable.module.scss"

export type TableMapperItem = {
  name: MetricNameType
  label: string
  renderLabel?: (value: number) => string
}

export type SubLabelType = string | TrendValueProps

export type SubLabelProps = {
  item: SubLabelType
}

export type TableColumnProps = {
  label: string | number
  subLabel?: string | number
  renderLabel?: (value: number) => string
}

export type TableDatasetType = {
  [key in MetricNameType]: any
}

export const TableColumn: React.FC<TableColumnProps> = ({
  label,
  subLabel,
  renderLabel,
}) => (
  <div className={styles.item}>
    <div>
      {renderLabel && typeof label === "number" ? renderLabel(label) : label}
    </div>
    {subLabel ? (
      <div className={styles.subLabel}>
        {typeof subLabel === "string" ? (
          <Typography type={TextType.h6} color={TextColorType.gray}>
            {subLabel.toUpperCase()}
          </Typography>
        ) : (
          <TrendValue value={Math.ceil(subLabel * 100)} />
        )}
      </div>
    ) : null}
  </div>
)

export type TableRowProps = {
  title: string
  mapper: TableMapperItem[]
  dataset: PerformanceMetrics
}

export const TableRow: React.FC<TableRowProps> = ({
  title,
  mapper,
  dataset,
}) => (
  <div className={styles.row}>
    <TableColumn label={title} key="title" />
    {mapper.map(({ name, renderLabel }, idx) => {
      const data = dataset[name]
      return (
        data && (
          <TableColumn
            label={data.Value}
            subLabel={data.Change ?? "-"}
            renderLabel={renderLabel}
            key={idx}
          />
        )
      )
    })}
  </div>
)

type TableBodyProps = {
  className?: string
  withDetails?: boolean
  children: ReactNode[]
} & TableRowProps

export const TableRowBox: React.FC<TableBodyProps> = ({
  title,
  mapper,
  dataset,
  children,
  withDetails = false,
}) => {
  const [isOpen, setOpen] = useState(false)
  return (
    <Box
      padding="none"
      className={clsx(styles.body, { [styles.withDetails]: withDetails })}
    >
      <TableRow dataset={dataset} mapper={mapper} title={title} />
      {children && isOpen ? (
        <div className={styles.detailsContainer}>{children}</div>
      ) : null}
      {withDetails && children ? (
        <Icon
          name="arrow-down"
          className={styles.openButton}
          rotateAngle={isOpen ? 180 : 0}
          onClick={() => setOpen(!isOpen)}
        />
      ) : null}
    </Box>
  )
}

export default TableRowBox
