/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react"
import clsx from "clsx"
import styles from "./ToggleButton.module.scss"

export type ToggleButtonProps = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  checked: boolean
  name?: string
  className?: string
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  checked,
  onChange,
  name = "toggle",
  className = "",
}) => (
  <label className={clsx(styles.switch, className)}>
    <input name={name} type="checkbox" onChange={onChange} checked={checked} />
    <span className={styles.slider} />
  </label>
)

export default ToggleButton
