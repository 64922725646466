import React, { useMemo } from "react"
import clsx from "clsx"
import { observer } from "mobx-react-lite"
import styles from "./AdSpendBudget.module.scss"
import Typography from "../../../../components/ui/Typography/Typography"
import { TextColorType, TextType } from "../../../../components/utils/enums"
import MonthAdSpendChart from "../../../../components/ui/MonthAdSpendChart/MonthAdSpendChart"
import BarChart, {
  DataSetType,
} from "../../../../components/ui/Charts/BarChart/BarChart"
import { labels } from "./MockData"
import { numberWithCommas } from "../../../../components/utils/numberUtils"
import { useStore } from "../../../../store"
import { extractMonthlyAdSpend } from "../../../../store/account-dashboard/dataTransformers"

type AdSpendBudgetProps = {
  className?: string
}

const yAxisRenderCallback = (value: any) => `$${numberWithCommas(value)}`

const AdSpendBudget: React.FC<AdSpendBudgetProps> = observer(
  ({ className }) => {
    const { accountDashboardStore } = useStore()
    const {
      loadingYearPerformance,
      currYearPerf,
      prevYearPerf,
      currentYear,
      previousYear,
    } = accountDashboardStore

    const data = useMemo(() => {
      const dataset: DataSetType[] = []
      if (currYearPerf)
        dataset.push(extractMonthlyAdSpend(currYearPerf, currentYear))
      if (prevYearPerf)
        dataset.push(extractMonthlyAdSpend(prevYearPerf, previousYear))
      return dataset
    }, [currYearPerf, prevYearPerf, previousYear, currentYear])

    return (
      <div className={clsx(styles.root, className)}>
        <Typography type={TextType.h1} color={TextColorType.primary} bold>
          Ad Spend Budget
        </Typography>

        <div className={styles.wrapper}>
          <div className={styles.column}>
            <MonthAdSpendChart date={Date.now()} />
          </div>
          <div className={styles.column}>
            <BarChart
              dataset={data}
              labels={labels}
              isLoading={loadingYearPerformance}
              title="Historical Monthly Ad Spend"
              period="(vs Previous year)"
              yAxisRender={yAxisRenderCallback}
            />
          </div>
        </div>
      </div>
    )
  }
)

export default AdSpendBudget
