/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react"
import clsx from "clsx"
import { useHistory } from "react-router-dom"
import { observer } from "mobx-react-lite"
import PrivateLayout from "../../layouts/PrivateLayout/PrivateLayout"
// import Button from "../../components/ui/Button/Button"
// import { ButtonColor, ButtonType } from "../../components/utils/enums"
import ListItem from "./components/ListItem/ListItem"
import getListItems from "./MockData"
import ColumnNames from "./components/ColumnNames/ColumnNames"
// import Pagination from "../../components/ui/Pagination/Pagination"
import { accountNavItems, accountListNavItems } from "../navOptions"
import { NavOption } from "../../components/ui/NavBar/NavBar"
import { useStore } from "../../store"
import Loader from "../../components/ui/Loader/Loader"
import { Option } from "../../framework/types/types"

import styles from "./AccountList.module.scss"

export type TableMapper = Option & {
  isSortable?: boolean
}

const tableMapper: TableMapper[] = [
  { label: "Profile", value: "profile", isSortable: true },
  { label: "Industry", value: "industry", isSortable: false },
  { label: "Total contract ad spend", value: "totalSpend", isSortable: false },
  { label: "Profile status", value: "profileStatus", isSortable: false },
  { label: "Profile completion", value: "competition", isSortable: false },
  {
    label: "Linked adwords account",
    value: "linkedAccount",
    isSortable: false,
  },
  { label: "Campaign status", value: "campaignStatus", isSortable: false },
]

type AccountListProps = {
  className?: string
}

const itemList = getListItems()

const AccountList: React.FC<AccountListProps> = observer(({ className }) => {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [sortBy, setSortBy] = useState({ value: "none", direction: false })
  const [checkedItems, setCheckedItems] = useState<string[]>([])
  // const [page, setPage] = useState(1)
  const history = useHistory()
  const { accountStore } = useStore()

  useEffect(() => {
    accountStore.loadSortedAccountList(sortBy)
    console.log(sortBy)
  }, [accountStore, sortBy])

  const handleBack = () => {
    // TODO back
  }

  const handleAction = (id: number) => (navOption: NavOption) => {
    history.push(`/account/${id}${navOption.value}`)
  }

  const items = itemList

  const handleCheckAll = () => {
    if (!isAllChecked) {
      const ids = items.map((item) => item.id)
      setCheckedItems(ids)
      setIsAllChecked(true)
    } else {
      setIsAllChecked(false)
      setCheckedItems([])
    }
  }

  const handleCheck = (id: string) => {
    const hasId = checkedItems.includes(id)
    hasId
      ? setCheckedItems((prev) => prev.filter((item) => item !== id))
      : setCheckedItems((prev) => [...prev, id])
  }

  // const handlePageChange = (page: number) => {
  //   setPage(page)
  // }

  return (
    <PrivateLayout
      navBar={{
        currentTab: accountListNavItems[0],
        options: accountListNavItems,
        title: "Modus Hotels",
      }}
      onBack={handleBack}
    >
      <div className={clsx(styles.root, className)}>
        {/* <div className={styles.buttonsWrapper}>
          <Button
            color={ButtonColor.primary}
            startIcon={{ name: "trash" }}
            variant={ButtonType.ghost}
            onClick={handleDeleteAllChosen}
          >
            Delete all chosen
          </Button>

          <Button
            color={ButtonColor.primary}
            variant={ButtonType.contained}
            onClick={handleAddAccount}
          >
            Add Account
          </Button>

          <div className={styles.button}>
            <Button
              color={ButtonColor.primary}
              variant={ButtonType.outlined}
              onClick={handleBulkUpload}
              disabled
            >
              Add Account Bulk Upload
            </Button>
          </div>
        </div> */}

        <div>
          <ColumnNames
            mapper={tableMapper}
            checked={isAllChecked}
            onChange={handleCheckAll}
            onSort={setSortBy}
          />
          <Loader isLoading={accountStore.loading} />
          {accountStore.accountList?.map((data: any) => (
            <ListItem
              {...data}
              checked={checkedItems.includes(data.id)}
              onChange={() => handleCheck(data.id)}
              options={accountNavItems}
              onAction={handleAction(data.id)}
              key={data.id}
            />
          ))}
        </div>
        {/* <div className={styles.pagination}>
          <Pagination
            currentPage={page}
            onChange={handlePageChange}
            total={itemList.length / 25}
          />
        </div> */}
      </div>
    </PrivateLayout>
  )
})

export default AccountList
